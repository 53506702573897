.page_title {
  font-size: 36pt;
  font-weight: bold;
  color: white;
  margin-top: -20px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  background-color: var(--site-background);
  padding-top: 80px;
}

.backIconContainer {
  position: absolute;
  top: 15px;
  left: calc((50vw - 400px) + 40px);
}

.backArrow {
  color: white;
  font-size: 42px;
  cursor: pointer;
}


.tabs {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  padding-bottom: 20px;
}

.tabs button,
.edit_button,
.link_button {
  background-color: rgb(51, 0, 77);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  cursor: pointer;
}

.edit_button {
  position: absolute;
  margin-left: 775px;
  width: 25px;
  border: 1px solid #a5a3a3;
  cursor: pointer;
}

.link_button {
  position: absolute;
  margin-right: 775px;
  width: 25px;
  border: 1px solid #a5a3a3;
  cursor: pointer;
}

.copy_message {
  position: absolute;
  right: calc((50vw - 400px) + 30px);
  top: 100px;
  padding: 8px;
  background-color: rgb(40, 38, 38); /* Green background */
  color: white;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  animation: fadeOut 2s ease-out;
}

/* Animation to fade out the message */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.profileContainer {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  justify-content: center;
  align-items: center;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}

.username {
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
  margin-right: auto;
  text-align: left;
  width: 600px;
  margin-top: 10px;
}

.profilePic {
  width: 180px;
  height: 180px;
  object-fit: cover; /* ensures the aspect ratio is maintained */
  border-radius: 50%;
  margin-top: -40px;
  margin-right: auto;
  margin-left: 20px;
  z-index: 100;
}

.relationship_group {
  border: 1px solid #e0e0e0;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.toggle_button {
  background-color: #2b035c;
  border-color: rgb(0, 0, 0);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle_button:hover {
  background-color: #37003a;
}

.relationship_group {
  width: 700px;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(24, 23, 23);
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns, adjust as needed */
  gap: 16px;
}

.gallery_requests {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns, adjust as needed */
  gap: 50px;
}

.user img {
  width: 80px;
  height: 80px;
  border-radius: 50%; /* makes the image circular */
  object-fit: cover; /* ensures the aspect ratio is maintained */
  border: 1px solid #000000;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
}

.delete_button {
  background-color: red;
  border-color: rgb(0, 0, 0);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-right: 15px; /* to give space between Delete and Show button */
  margin-left: auto;
}

.delete_button:hover {
  background-color: darkred;
}

.rating_filters {
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rating_gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  overflow-y: auto;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
}

.rating_gallery p {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 5px;
}

.more_button {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.rating_range {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 35%;
}

.rating_range_label {
  padding-top: 5px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rating_count {
  padding-top: 30px;
}

.checkbox {
  padding-left: 15px;
}

.category_container {
  width: 950px;
  padding-left: 20px;
  margin-top: 20px;
}

.categoryInfo {
  display: grid;
  grid-template-columns: repeat(
    5,
    minmax(0, 1fr)
  ); /* Create as many columns as needed */
  justify-content: center; /* This will center the columns themselves */
  align-items: center;
  margin-bottom: 20px;
  max-width: 950px;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.category_icon {
  width: 65px;
  height: 65px;
  object-fit: scale-down; /* ensures the aspect ratio is maintained */
  margin-right: 10px;
  cursor: pointer;
  filter: invert();
}

.categories_title {
  padding-top: 20px;
  color: white;
  text-align: center;
}

.categoryContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: rgb(50, 48, 48);
  border-radius: 30px;
  height: 230px;
  width: 180px;
  cursor: pointer;
}

.charts {
  padding-top: 40px;
  height: 350px; /* You can adjust this height */
  margin-right: auto;
  margin-left: auto;
  width: 900px; /* The width remains fixed */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.ratingStats {
  cursor: pointer;
}

.ratingStat:hover {
  background-color: rgba(33, 34, 41, 0.8);
  color: white;
}

.ratingStat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  width: 80px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;  
  height: 50px;
  gap: 0px;
}

.chartTitle {
  color: white;
  font-size: 18pt;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  padding-bottom: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.selectedCategory {
  background-color: rgb(73, 4, 95);
}

.remove_button {
  margin-bottom: -20px;
  z-index: 10;
  margin-left: 70px;
  border-radius: 25%;
  cursor: pointer;
  color: red;
  background-color: rgb(23, 3, 36);
}

.flags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 20px;
  left: calc((50vw - 400px) + 30px);
}

.flags img {
  width: 50px;
  height: 30px;
  object-fit: stretch; /* ensures the aspect ratio is maintained */
}

.friend_request_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
}

.friend_request_buttons button {
  font-size: 24px;
  background-color: rgb(32, 31, 31);
  border: 1px solid #242121;
  cursor: pointer;
}

.accept_button {
  color: green;
}

.decline_button {
  color: rgb(241, 0, 0);
}

.requests_header {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.friend_requests_main {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category_info_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  gap: 5px;
}

.categoryName {
  font-size: 14pt;
  color: white;
  text-align: center;
  height: 10px;
  margin-top: 2px;
}

.logout {
  font-size: 12px;
  padding: 0px;
  cursor: pointer;
  margin-top: 10px;
  color: white;
}

.logout:hover {
  text-decoration: underline;
}

.userType {
  font-size: 18px;
  color: #CCCCCC;
  margin-top: 0px;
  margin-right: auto;
  margin-left: 10px;
  padding-top: 5px;
}

.userType2 {
  font-size: 16px;
  color: transparent;
  margin-top: 0px;
  margin-right: auto;
  margin-left: 50px;
}

.bioText {
  color: white;
  margin-top: 0px;
  width: 650px;
  text-align: left;
  padding-bottom: 10px;
  margin-right: auto;
  margin-left: 30px;
}

.ratingCounts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  margin-right: auto;
  position: absolute;
  top: 440px;
  margin-left: 0px;
}

.ratingLabel {
  font-size: 12pt;
  color: #CCCCCC;
  margin: 0;
  padding: 0;
  padding-top: 5px;

}

.ratingText {
  font-size: 16pt;
  color: white;
  cursor: pointer;
  margin: 0;
  padding: 0;
 
}

.categoryCount {
  font-size: 12pt;
  color: #CCCCCC;
  margin: 0;
  text-align: center;
}

.relationshipInfo {
  padding-top: 5px;
  width: 150px;
  align-items: "center";
  border-width: 1px solid black;
  border-radius: 15px;
  align-self: "center";
  margin-left: 10;
  margin-right: 10;
  margin-top: 10;
  height: 120;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.relationshipText {
  color: white;
  font-size: 16pt;
  cursor: pointer;
  padding-left: 20px;
}

.networkImage {
  width: 100px;
  height: 100px;
  object-fit: cover; /* ensures the aspect ratio is maintained */
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
}

.removeButton {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: red;
  font-size: 14pt;
}

.deleteButton {
  position: absolute;
  top: -25px;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: red;
  font-size: 14pt;
}

.relationshipButtons {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  margin-bottom: 10px;
  color: white;
}

.relationList {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(150px, 1fr)
  ); /* Creates columns that fill the space and wrap as needed */
  gap: 10px; /* Space between items */
  padding: 10px;
}


.settingsIconContainer {
  position: absolute;
  top: 15px;
  right: calc((50vw - 400px) + 20px);
}

.settingsIcon {
  color: white;
  font-size: 28px;
  cursor: pointer;
  width: 50px;
}

.filterButton {
  background-color: rgba(86, 0, 107, 0.6);
  color: white;
  border: 1px solid rgb(194, 185, 185);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  font-size: 24pt;
  padding: 10px;
}

.filterContainer{
  position: fixed;
  right: calc((50vw - 550px));
  bottom: 110px;
  z-index: 5000;
}

.switchContainer {
  color: white;
  cursor: pointer;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: 50px;
}

.ratingGallery {
  margin-top: 20px;
}

.ratingFilters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0px;
}

.dropdownFilters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  margin-top: 50px;
  /* Sticky behavior */
  position: -webkit-sticky; /* Safari support */
  position: sticky;
  top: 70px; /* Sticks to the top when scrolling */
  z-index: 100; /* Ensure it stays on top of other content */
  background-color: var(
    --site-background
  ); 
  padding: 10px; /* Optional: Adds padding to the sticky element */
}

.ratingRangeLabel {
  color: white;
  font-size: 16pt;
}

.ratingSlider {
  width: 500px;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: center;
  width: 50px;
  color: white;
}

.checkboxFilters {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.checkboxFilters input[type="checkbox"] {
  -webkit-appearance: none; /* Remove default styling for WebKit/Blink browsers */
  -moz-appearance: none; /* Remove default styling for Firefox */
  appearance: none;
  width: 25px; /* Checkbox size */
  height: 25px; /* Checkbox size */
  background: rgb(49, 47, 47); /* Background color */
  border-radius: 3px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  position: relative;
  border: 1px solid rgb(152, 145, 145);
}

.checkboxFilters input[type="checkbox"]:checked {
  background-color: purple; /* Background color when checked */
  border-color: purple; /* Border color when checked */
}

.checkboxFilters input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.sortButton {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 20pt;
  padding: 10px;
  z-index: 1000;
  margin-left: -55px;
  margin-top: -15px;
}

.searchInput {
  display: flex;
  align-items: center;
  margin-top: -10px;
  border: none;
  border-radius: 15px;
  height: 25px;
  width: 200px;
  color: white;
  font-size: 12pt;
  outline: none;
  background-color: var(
    --site-search
  ); /* Define variable in :root or substitute with a hex value */
}

.modal {
  position: fixed; /* Fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent background */
  z-index: 1000; /* High z-index to keep it on top */
  display: flex; /* Flexbox to position the child elements */
  align-items: start; /* Aligns content to the top */
  justify-content: flex-end; /* Aligns content to the right */
}

.modalContent {
  position: absolute;
  top: 440px;
  right: calc((50vw - 480px));
  margin-top: 10px; /* Top margin for a bit of spacing from the top edge */
  margin-right: 10px; /* Right margin for a bit of spacing from the right edge */
  background-color: rgb(24, 23, 23);
  color: white; /* White text color */
  width: 150px; /* Width of the modal content */
  padding: 5px; /* Padding inside the modal content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Some shadow for aesthetics */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #3e3838; /* Border for the modal content */
}

.optionText {
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  align-self: center;
  align-content: center;
  width: 100%;
  color: white;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.modalBody h3 {
  cursor: pointer; /* Cursor to indicate clickable */
  transition: background-color 0.3s; /* Transition for hover effects */
}

.modalBody h3:hover {
  background-color: #555; /* Darker background on hover */
}

.user_groups {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  position: absolute;
  top: 350px;
  margin-left: 700px;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.tagIcon:hover {
  background-color: #555; /* Darker background on hover */
}

.friendIcon {
padding-right: 10px;
margin-left: -5px;
}

.tagIcon {
  width: 60px;
  height: 30px;
  object-fit: contain;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.followButton {
  background-color: var(
    --site-purple
  ); /* Define variable in :root or substitute with a hex value */
  color: white;
  border: 1px solid #242121;
  cursor: pointer;
  padding: 5px;
  font-size: 14pt;
  border-radius: 15px;
  width: 250px;
  text-align: center;
  height: 40px;
  align-content: center;
}

.followButton:hover {
  background-color: #440248;
}

.tagInputSearch {
  background-color: var(
    --site-search
  ); /* Define variable in :root or substitute with a hex value */
  color: white;
  border: 1px solid #242121;
  padding: 5px;
  font-size: 14pt;
  border-radius: 15px;
  width: 200px;
  text-align: center;
  height: 40px;
  align-content: center;
  outline: none;
}

.tagInput {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}

.addTagButton {
  background-color: var(
    --site-purple
  ); /* Define variable in :root or substitute with a hex value */
  color: white;
  border: 1px solid #242121;
  cursor: pointer;
  padding: 5px;
  font-size: 14pt;
  border-radius: 15px;
  width: 200px;
  text-align: center;
  height: 40px;
  align-content: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8px;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 600px;
  align-items: center;
  justify-content: flex-start;
  max-height: 100px;
  padding-top: 0px;
  overflow-y: auto;
  color: white;
  gap: 20px;
  margin-left: 30px;
}

.tagBubble {
  background-color: rgba(0,0,0,0.3);
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 100px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.tagBubble:hover {
  background-color: rgba(0,0,0,0.5);
}

.tagClose {
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* color for the X */
  font-size: 14px;
}

.input_error_message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.thumb {
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: grab;
}

.track {
  top: 10px;
  background: #ccc;
  height: 5px;
  border-radius: 5px;
}

.chartTitles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 700px;
  margin-right: auto;
  padding-bottom: 5px;
}

.chartTitles h2 {
  font-size: 24px;
  font-weight: normal;
  padding-left: 20px;
}

.profileBackground {
  position: fixed;
  object-fit: cover;
  opacity: 0.05;
  z-index: -1;
  height: 100%;
  width: 1000px;
}


.wordCloudButton {
  background-color: var(--site-purple); /* Ensure site_purple is defined in your CSS variables */
  height: 40px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  cursor: pointer;
  /* Add other styles as needed */
}

.wordCloudButtonText {
  color: white;
  font-size: 16px;
  /* Add other styles as needed */
}

.header {
  position: absolute;
  top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
}

.cloudHeader {
  position: absolute;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
}

.showWordCloudText {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-left: auto;  
  margin-right: auto;
  display: flex;
  cursor: pointer;
}

.imageHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 10;
}

.imageHeaderText {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.imageHeaderDate {
  color: #CCCCCC;
  font-size: 14px;
}

.cloudModal {
  position: relative;
  width: 800px;
  height: 100%;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  object-fit: scale-down;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
  position: absolute;
  top: 0;
  left: 500px;
  width: 1000px;
}

.cloudHeader {
  position: absolute;
  top: 20px;
  width: 800px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
}

.text {
  margin-left: 8px;
  font-size: 16px;
  color: white;
}

.loadingContainer {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.purpleSpinner {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3; /* Light grey background */
  border-top: 5px solid var(--site-purple);
  border-radius: 50%;
  animation: spinner 0.2s linear infinite;
}

.imageHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  z-index: 10;
}

.imageHeaderText {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.imageHeaderDate {
  color: #cccccc;
  font-size: 14px;
}

.cloudImage {
  width: 1000px;
  height: 700px;
  object-fit: contain;
}

.coverPhoto {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 0px 0px 10px 10px;
  opacity: 0.3;
  z-index: 1;
  margin-top: -20px;
}

.coverPhotoEmpty {
  width: 100%;
  height: 300px;
  background-color: #444141;
  object-fit: cover;
  border-radius: 0px 0px 10px 10px;
  opacity: 0.3;
  z-index: 1;
  margin-top: -20px;
}

.joinedText {
  color: transparent;
  font-size: 16px;
  font-weight: bold;
  width: 250px;
}

.filterIcon2 {
  color: white;
  font-size: 24px;
  cursor: pointer;
  width: 50px;
  margin-left: 10px;
  margin-top: -6px;
}

.profileInfoBlock {
  display: flex;
  flex-direction: row;
  gap: 0px;
  margin-right: auto;
}

.usernameBlock {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-right: auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
}


.row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  margin-top: -15px;
}

.ratingText {
  font-size: 1rem;
  margin: 0.5rem;
  display: inline-block;
}

.dot {
  display: inline-block;
  color: #CCCCCC;
  font-size: 24px;
}

.hoverBlock {
  cursor: pointer;
}

.hoverBlock:hover .ratingText {
  border-bottom: 0.2px solid rgb(255, 255, 255); /* Underline on hover */
}

.showChartsText {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-right: auto;
  display: flex;
  cursor: pointer;
}

.purpleSpinner {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3; /* Light grey background */
  border-top: 5px solid var(--site-purple);
  border-radius: 50%;
  animation: spinner 0.2s linear infinite;
  margin-left: 550px;
  margin-top: 100px;
}