.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 100000000000; /* Ensure it's over other content */
    display: flex; 
    align-items: center; 
    justify-content: center; /* These 3 lines help in centering the modal */
  }
  
  .modal {
    background-color: rgb(23, 22, 22); 
    padding: 20px;
    border-radius: 8px;
    border: none;
    width: 80%; /* or you can set a fixed width like 400px; */
    max-width: 500px; /* This ensures the modal doesn't get too big on large screens */
    height: 650px; /* or you can set a fixed height like 300px; */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* A little shadow for better depth perception */
    position: relative; /* Set relative positioning for the modal for any absolute positioned child components, if needed in future */
    z-index: 1000; /* Higher than the overlay */
  }
  
  .modal textarea {
    width: 95%;
    padding: 10px;
    height: 500px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    background-color: rgb(16, 16, 16);
    color: white;
    border-radius: 4px;
    resize: vertical; /* Allow vertical resizing */
    font-family: 'Pacifico', cursive;
  }

  .modal h2, .modal h4 {
    margin-top: 0;
    margin-bottom: 0px;
    color: white;
  }
  
  .modal button {
    padding: 8px 12px;
    margin-right: 10px;
    border: none;
    background-color: rgb(86, 0, 107);
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    width: 100px;
    height: 45px;
  }
  
  .modal button:hover {
    background-color: #2e0043;
  }
  
  .modal button:last-child {
    margin-right: 0;
  }

  .footer {
    padding-top: 0%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .user_info img {
    width: 50px;
    max-height: 50px;
    max-width: 50px;
    object-fit: scale-down;
    border-radius: 50%;
    margin-top: -10px;
  }

  .username {
    font-size: 24px;
    padding-left: 70px;
    display: flex;
    margin-top: -55px;
    color: white;
  }

  .timestamp {
    font-size: 18px;
    padding-left: 70px;
    display: flex;
    margin-top: -5px;
    color: #CCCCCC;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 80px;
    overflow-y:hidden;
    padding-top: 10px;
  }

  .categoryIcon {
    width: 40px; /* Adjust accordingly */
    height: 40px; /* Adjust accordingly */
    object-fit: contain; /* Adjusts how the image fits within the boundaries */
    filter: invert(1); /* Inverts the color of the image */
    margin-top: -3px;
    padding-right: 5px;
  }

  .header h1 {
    font-size: 48px;
    margin-top: -18px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 5px;
    color: white;
  }
