.page_title {
  font-size: 36pt;
  font-weight: bold;
  color: white;
  margin-top: -20px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  background-color: var(--site-background);
}

.backIconContainer {
  position: absolute;
  top: 15px;
  left: calc((50vw - 400px) + 40px);
}

.backArrow {
  color: white;
  font-size: 42px;
  cursor: pointer;
}


.tabs {
  display: flex;
  justify-content: space-evenly; /* Distribute tabs evenly across the available space */
  width: 700px; /* Adjust based on container requirements */
  margin: 10px 0; /* Optional margin adjustment */
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
}

.tabButton {
  background: none; /* No button background */
  border: none; /* No button border */
  color: #444; /* Default text color */
  padding: 10px; /* Adjust padding as needed */
  cursor: pointer; /* Pointer cursor for buttons */
  outline: none; /* Remove default focus border */
  font-size: 16px; /* Adjust font size */
  position: relative;
  width: 50%;
}

.active {
  color: white;
}

.active::after {
  content: ""; /* Pseudo-element for the underline */
  position: absolute;
  left: 0;
  bottom: -5px; /* Adjust position if needed */
  width: 100%;
  height: 2px; /* Thickness of the underline */
  background-color: var(--site-purple);
}

.quickAdd {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  gap: 20px;
}

.submitButton {
  background-color: var(--site-purple);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.googleContainer {
  color: black;
  margin-top: -10px;
}

.quickAddtext {
  color: white;
  margin: 0;
  padding: 0;

}

.input {
 margin-top: -25px;
 height: 25px;
 border-radius: 5px;
 padding-left: 10px;
}

.submitProductForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.submitProductFields {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.fieldLabel {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-bottom: 5px;
}

.textInput,
.textareaInput,
.selectInput {
  width: 600px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  font-family: var(--font-family);
  margin-top: 5px;
  outline: none;
}

.textareaInput {
  min-height: 80px;
  resize: vertical;
}

.selectInput {
  background-color: #fff;
  width: 620px;
}

.keywordsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.keywordBubble {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  color: #fff;
}

.removeKeyword {
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
}

.submitProductButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.addButton,
.submitButton {
  padding: 8px 15px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  background-color: var(--site-purple);
}

.addButton:hover,
.submitButton:hover {
  background-color: #34003a;
}

.imagePreview {
  width: 300px;
  height: 300px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.purpleSpinner {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3; /* Light grey background */
  border-top: 5px solid var(--site-purple);
  border-radius: 50%;
  animation: spinner 0.2s linear infinite;
  position: absolute;
  top: 140px;
  left: calc(50vw - 15px);
}

.verified {
  color: #CCCCCC;
  width: 500px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.thanksText {
  margin-top: 100px;
}

.box {
  background-color: #ffffff; /* White background for the content boxes */
  border-radius: 8px; /* Rounded corners for the boxes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 20px; /* Space between each box */
  overflow: hidden; /* Ensures no content spills out */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.box:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Darker shadow on hover for a lifting effect */
}

/* Styling for the title button */
.boxTitle {
  background-color: var(--site-purple); /* A pleasant blue background color */
  color: white; /* White text color */
  padding: 15px 20px; /* Padding for aesthetic spacing */
  width: 100%; /* Makes the button extend full container width */
  border: none; /* No border for a cleaner look */
  text-align: left; /* Aligns text to the left */
  font-size: 18px; /* Larger font size for readability */
  cursor: pointer; /* Pointer cursor on hover */
  outline: none; /* Removes the outline to prevent a boxy look on click */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.boxTitle:focus, .boxTitle:hover {
  background-color: rgb(108,0,97)/* Darker shade of blue on hover/focus */
}

/* Content container styling */
.contentContainer {
  padding: 20px; /* Padding inside the content area */
}

.contentText {
  font-size: 16px; /* Adequate font size for content readability */
  color: #333; /* Dark gray color for the text */
  line-height: 1.6; /* Line height for better readability */
  margin-bottom: 20px; /* Space before the button */
}

/* Image button styling */
.image {
  background: none; /* No background to let the image be the focus */
  border: none; /* No border for a cleaner look */
  padding: 0; /* No padding needed */
  display: block; /* Block display to handle the image sizing correctly */
  cursor: pointer; /* Cursor pointer to indicate it's clickable */
}

.image img {
  max-width: 100%; /* Ensures image does not exceed the container width */
  height: auto; /* Maintains aspect ratio */
  display: block; /* Ensures no extra space below the image */
}


