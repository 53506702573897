.page_title {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-top: 20px;
  width: 600px;
  text-align: left;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  background-color: var(--site-background);
  padding-top: 80px;
}

.backIconContainer {
  position: absolute;
  top: 15px;
  left: calc((50vw - 400px) + 40px);
}

.moreIconContainer {
  position: absolute;
  top: 70px;
  right: calc((50vw - 500px));
  width: 50px;
  cursor: pointer;
  align-self: center;
  justify-content: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  display: flex;
  z-index: 6000;
  cursor: pointer;
}

.arrowIconContainer {
  position: absolute;
  top: 430px;
  right: calc((50vw - 490px));
  z-index: 5000;
  cursor: pointer;
}

.playContainer {
  position: absolute;
  top: 380px;
  z-index: 5000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin-left: 280px;
}

.previewText {
  color: #CCCCCC;
  font-size: 12pt;
  margin: 0px;
  margin-top: 10px;
}
.categoryIconContainer {
  position: absolute;
  top: 105px;
  left: calc((50vw - 400px) + 20px);
}

.moreIcon {
  color: white;
  font-size: 30px;
  cursor: pointer;
  align-self: center;
  justify-content: center;
  align-content: center;
}

.linkIcon {
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.backArrow {
  color: #cccccc;
  width: 30px;
  height: 30px;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  margin-left: 40px;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  margin-top: -10px;
}

.categoryIcon {
  width: 30px;
  height: 30px;
  filter: invert();
}

.image_container {
  width: 240px;
  position: relative; /* Position relative for absolute positioning of children */
  height: 360px;
  margin-left: 20px;
  margin-right: auto;
}

.product_photo {
  width: 240px;
  height: 360px;
  object-fit: cover;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
}

.images_arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px; /* Space between arrows and photo details */
  z-index: 1000;
  height: 50px;
}

.images_arrows button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px; /* Space between arrows */
  color: rgb(255, 255, 255);
  font-size: 16pt;
}

.photo_details_container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: row;
  gap: 40px;
  width: 250px;
}

.photo_details {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.photo_details img {
  width: 30px; /* Adjust size as needed */
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px; /* Space between image and text */
}

.likes {
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.ratingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  margin: 0;
  width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}

.arrowLeft {
  color: #cccccc;
  font-size: 48px;
  cursor: pointer;
  padding-top: 15px;
}

.arrowRight {
  color: #cccccc;
  font-size: 48px;
  cursor: pointer;
  padding-top: 15px;
}

.selectedRating {
  color: white;
  font-size: 64px;
  font-weight: normal;
}

.ratingDescription {
  font-size: 20pt;
  color: white;
  text-align: center;
  font-style: italic;
  margin-top: -70px;
}

.subRatingContainer2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin-top: 20px;
}

.subRatingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.ratingDate {
  color: #cccccc;
  font-size: 14px;
  margin-top: 0px;
}

.averageRating {
  color: white;
  font-size: 36px;
}

.ratingLabel {
  color: #cccccc;
  font-size: 14px;
  margin-top: -40px;
}

.ratingCount {
  color: white;
  font-size: 14px;
  margin-top: -10px;
}

.otherActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 0px;
  z-index: 900;
}

.reviewLink {
  color: white;
  font-size: 16pt;
  cursor: pointer;
  margin-top: 5px;
}

.reviewLink:hover {
  text-decoration: underline;
}

.description {
  color: white;
  font-size: 12pt;
  text-align: center;
  max-height: 7000px;
  min-height: 0px;
  overflow: auto;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.description2 {
  color: #cccccc;
  font-size: 12pt;
  text-align: left;
  max-height: 110px;
  min-height: 0px;
  overflow: auto;
  width: 600px;
}

.ratersContainer {
  color: white;
  margin-top: -20px;
}

.checkboxFilters input[type="checkbox"] {
  -webkit-appearance: none; /* Remove default styling for WebKit/Blink browsers */
  -moz-appearance: none; /* Remove default styling for Firefox */
  appearance: none;
  width: 25px; /* Checkbox size */
  height: 25px; /* Checkbox size */
  background: rgb(49, 47, 47); /* Background color */
  border-radius: 3px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  position: relative;
  border: 1px solid rgb(152, 145, 145);
}

.checkboxFilters input[type="checkbox"]:checked {
  background-color: purple; /* Background color when checked */
  border-color: purple; /* Border color when checked */
}

.checkboxFilters input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.sortButton {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 20pt;
  padding: 10px;
  z-index: 500;
  margin-left: -55px;
  margin-top: -15px;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: center;
  width: 50px;
  color: white;
}

.checkboxFilters {
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.raterGalleryTitle {
  color: white;
  font-size: 24pt;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  width: 500px;
  margin-bottom: 0px;
  text-align: center;
}

.sortOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.productRaterGallery {
  width: 1000px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.productRaterCard {
  border: none;
  border-radius: 10px;
  width: 200px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: top;
  height: 220px;
  gap: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
}

.productRaterCard :hover,
.cardWithReview :hover {
  scale: 1.01;
}

.cardWithReview {
  border: none;
  border-radius: 10px;
  width: 400px;
  align-items: top;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 200px;
  gap: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  padding: 10px;
}

.productRaterCard img,
.cardWithReview img {
  margin: 0;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid black;
  background-color: black;
  cursor: pointer;
  margin-top: 10px;
}

.productRaterCard h4,
.cardWithReview h4 {
  padding-bottom: 0px;
  margin: 0;
  padding-top: 5px;
  margin-bottom: -10px;
}

.productRaterCard h5,
.cardWithReview h5 {
  padding-bottom: 0px;
  padding-top: 0px;
  margin: 0;
  font-size: 32px;
  margin-top: -10px;
}

.productRaterCard h6,
.cardWithReview h6 {
  padding-bottom: 0px;
  margin: 0;
  font-size: 12px;
  margin-top: 0px;
  color: #cccccc;
  height: 10px;
}

.product-rater-review-link {
  margin-top: -10px;
  text-decoration: underline;
  cursor: pointer;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.reviewText {
  color: white;
  font-size: 14px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  width: 250px;
  margin-left: 20px;
  max-height: 200px;
  cursor: pointer;
  overflow: auto;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  height: 150px;
}

.copy_message {
  position: absolute;
  right: calc((50vw - 400px) + 30px);
  top: 100px;
  padding: 8px;
  background-color: rgb(40, 38, 38); /* Green background */
  color: white;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  animation: fadeOut 2s ease-out;
}

.modal {
  position: fixed; /* Fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent background */
  z-index: 7000; /* High z-index to keep it on top */
  display: flex; /* Flexbox to position the child elements */
  align-items: start; /* Aligns content to the top */
  justify-content: flex-end; /* Aligns content to the right */
}

.modalContent {
  position: absolute;
  top: 60px;
  right: calc((50vw - 500px) + 0px);
  margin-top: 10px; /* Top margin for a bit of spacing from the top edge */
  margin-right: 10px; /* Right margin for a bit of spacing from the right edge */
  background-color: rgb(24, 23, 23);
  color: white; /* White text color */
  width: 150px; /* Width of the modal content */
  padding: 5px; /* Padding inside the modal content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Some shadow for aesthetics */
  border-radius: 5px; /* Rounded corners */
  z-index: 7000;
}

.optionText {
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  align-self: center;
  align-content: center;
  width: 100%;
  color: white;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.modalBody h3 {
  cursor: pointer; /* Cursor to indicate clickable */
  transition: background-color 0.3s; /* Transition for hover effects */
}

.modalBody h3:hover {
  background-color: #555; /* Darker background on hover */
}

.images_arrows {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  z-index: 1000;
  height: 50px;
  width: 700px;
  margin-top: 200px;
  left: -50px;
}

.image_count {
  color: #cccccc;
  font-size: 10pt;
  margin-top: -5px;
  margin-left: 265px;
}

.uploadContainer {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.likeButton {
  font-size: 24px;
  cursor: pointer;
}

.likeContainer {
  position: absolute;
  cursor: pointer;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
}

.likeCount {
  color: #cccccc;
  font-size: 14px;
  margin: 0;
  text-align: center;
}

.coverPhoto {
  width: 1000px;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
  opacity: 0.07;
  margin-top: -20px;
  position: absolute;
}

.creatorInfo {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  padding-bottom: 10px;
}

.creator {
  color: #cccccc;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 16pt;
  font-weight: 500;
}

.creator:hover {
  text-decoration: underline;
}

.mainKeywords {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-top: -30px;
  margin-left: -10px;
  overflow: auto;
  max-width: 700px;
  height: 40px;
}

.keyword {
  color: #cccccc;
  font-size: 16px;
  cursor: pointer;
  font-style: italic;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px;
  border-radius: 20px;
  z-index: 5000;
  cursor: pointer;
  background-color: rgba(0,0 ,0, 0.5);
  height: 20px;
  overflow: hidden;
  width: 70px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  
}

.keyword:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Styles for the stars container */
.starsContainer {
  display: flex;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 10px;
}

.star {
  cursor: pointer;
  transition: color 0.3s ease;
  width: 55px;
  height: 55px;
}

.highlightedStar {
  color: purple; /* Highlight color */
}

.star:hover {
  color: darkviolet;
}

/* Rating submit button */
.submitButton {
  background-color: var(--site-purple);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 350px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: 50px;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  margin-top: -30px;
}

.submitButton:hover {
  background-color: rgba(86, 0, 107, 0.8);
  scale: 1.05;
}

.submitText {
  color: white;
  font-size: 20pt;
  padding-top: 20px;
  font-weight: normal;
}

.wishlistContainer {
  display: column;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.wishlistText {
  color: #cccccc;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.modalContent h1 {
  font-size: 96px;
  height: 120px;
  margin-top: -30px;
}

.ratingOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  align-items: center;
  align-self: center;
  align-content: center;
}

.wishlist,
.skip {
  color: var(--site-purple);
  cursor: pointer;
  font-size: 48px;
}

.wishlist:hover,
.skip:hover {
  color: rgba(86, 0, 107, 0.8);
  scale: 1.05;
}

.ratingTitle {
  font-size: 18pt;
  color: white;
  text-align: center;
  font-weight: bold;
  max-height: 35px;
  overflow: hidden;
}

.ratingOptions p {
  margin-top: -2px;
}

.reviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.review {
  width: 550px;
  min-height: 60px;
  max-height: 200px;
  margin-top: -10px;
  padding: 10px;
  border-radius: 15px;
  background-color: transparent;
  color: white;
  font-size: 16pt;
  border: 1px solid rgb(51, 49, 49);
  resize: none; /* Prevent manual resizing */
  overflow-y: hidden; /* Hide scrollbar when not needed */
}

.ratingOptions h3 {
  color: white;
  font-size: 14pt;
  margin-top: -5px;
}

.imageContainer {
  position: absolute;
  bottom: 5px;
  right: -5px;
}

.ratingInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin: 0;
  width: 900px;
  height: 30px;
}

.ratingInfoEmpty {
height: 70px;
font-size: 16px;
color: #CCCCCC;
}

.ratingText {
  color: white;
  font-size: 14pt;
  margin: 0;
  width: 145px;
  text-align: left;
}

.ratingStar {
  color: var(--site-purple);
  font-size: 22px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: -5px;
}

.ratingText2 {
  color: white;
  font-size: 18pt;
  margin: 0;
  width: 50px;
  text-align: center;
}

.ratingText3 {
  color: #cccccc;
  font-size: 14pt;
  padding-left: 5px;
  margin: 0;
}

.tabsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  width: 550px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.tab {
  flex: 1;
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 10px;
  position: relative; /* For positioning the underline */
  color: #cccccc;
}

.activeTab {
  font-weight: bold;
  color: white;
}

.activeTabUnderline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--site-purple);
}

.activeTabText,
.tabText {
  font-size: 16px;
}

.productMainInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 900px;
  margin-left: 30px;
  z-index: 3000;
}

.startContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  margin-top: 5px;
  width: 900px;
}

.allRatings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
}

.hideIcon {
  color: #CCCCCC;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  top: 235px;
  margin-left: 300px;
}
