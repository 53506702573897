.page_title {
    font-size: 36pt;
    font-weight: bold;
    color: white;
    margin-top: -20px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    background-color: var(
      --site-background
    );
  }

.blogList {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: white;

  }
  
  .blogSummary {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 600px;
    margin-bottom: 20px;
  }
  
  .blogSummary h2 {
    font-size: 1.8em;
    text-align: center;
  }
  
  .blogSummary p {
    margin-bottom: 10px;
  }