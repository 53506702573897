.header {
    background-color: var(--site-background);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height: 70px;
    width: 100vw;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 9000;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 180px;
    margin-left: -10px;
  }
  
  .logoImage {
    width: 160px;
    height: auto;
    cursor: pointer;
    margin-left: 20px;
  }

  .headerProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 35px;
    width: 180px;
    padding-right: 0px;
  }

  .navIcon {
    width: 25px;
    height: 25px;
    color: #eae3e3;
    cursor: pointer;
  }

  .navIcon:hover {
    color: white;
    scale: 1.04;
  }

  .loginContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 0px;
    width: 120px;
    padding-right: 0px;
  }
  

  