.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  padding: 10px;
  max-height: 90vh;
}
.inputContainer {
  width: 600px;
  background-color: rgb(30, 30, 30);
  border-radius: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 1px;
  padding-left: 10px;
  z-index: 10000;
  justify-content: space-between;
}

.searchIcon {
  color: #cccccc;
  font-size: 24px;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.searchInput {
  background-color: transparent;
  border: none;
  outline: none;
  color: #ede7e7;
  font-size: 18px;
  width: 400px;
}

.searchCategory {
  background-color: rgb(30, 30, 30);
  color: #ede7e7;
  outline: none;
  border: none;
}

/* Dropdown styles */
.searchDropdown {
  list-style-type: none;
  width: 540px;
  overflow-y: auto;
  max-height: 90vh;
  z-index: 5000;
  background-color: rgb(12, 12, 12);
  position: absolute;
  margin-top: 50px;
  margin-left: 10px;
}

.searchItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 100px;
  width: 100%;
  justify-content: flex-start;
}

.searchItem:hover {
  background-color: #252323;
}

.itemLeft,
.itemMiddle,
.itemRight {
  display: flex;
  align-items: center;
}

.itemLeft {
  width: 60px;
}

.itemRight {
  width: 80px;
  justify-content: center;
}

.itemMiddle {
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 30px;
  align-items: flex-start;
  width: 520px;
}

.searchImage {
  width: 60px;
  height: 90px;
  border-radius: 5px;
  object-fit: cover;
  padding-left: 10px;
}

.itemIcon {
  filter: invert(1);
  padding-right: 10px;
  width: 20px;
  height: 20px;
}

.title {
  color: #ffffff;
  font-size: 20px;
  height: 45px;
  overflow: hidden;
  font-size: 16px;
}

.creator {
  font-size: 14px;
  color: #CCCCCC;
  height: 20px;
  overflow: hidden;
}

/* Hide last border-bottom */
.searchItem:last-child {
  border-bottom: none;
}

.iconCreator {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
}

.secondaryInfo {
  color: #CCCCCC;
  font-size: 14px;
}

.dropdownOpen {
  position: absolute;
  background-color: transparent;
  width: 2000px;
  height: 1000px;
  top: 0;
  left: 0;

}
