.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 80px;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  background-color: var(
    --site-background
  );
  z-index: 0;
}

.page_title {
  font-size: 36pt;
  font-weight: bold;
  color: white;
  margin-top: -20px;
}

.filterButton {
  background-color: rgba(86, 0, 107, 0.6);
  color: white;
  border: 1px solid rgb(194, 185, 185);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  font-size: 24pt;
  padding: 10px;
  z-index: 5000;
}

.filterContainer{
    position: fixed;
    right: calc((50vw - 580px) + 25px);
    bottom: 100px;
    z-index: 5000;
}

.ratingGallery {
    margin-top: -40px;
}

.ratingFilters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -30px;
}

.dropdownFilters {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  z-index: 1000;
}

.ratingRangeLabel {
  color: white;
  font-size: 16pt;
}

.ratingSlider {
  width: 500px;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: center;
  width: 50px;
  color: white;
}

.checkboxFilters {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.checkboxFilters input[type="checkbox"] {
    -webkit-appearance: none; /* Remove default styling for WebKit/Blink browsers */
    -moz-appearance: none; /* Remove default styling for Firefox */
    appearance: none;
    width: 25px; /* Checkbox size */
    height: 25px; /* Checkbox size */
    background: rgb(49, 47, 47); /* Background color */
    border-radius: 3px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    position: relative;
    border : 1px solid rgb(152, 145, 145);
}

.checkboxFilters input[type="checkbox"]:checked {
    background-color: purple; /* Background color when checked */
    border-color: purple; /* Border color when checked */
}

.checkboxFilters input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    left: 7px;
    top: 0px;
    width: 7px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.thumb {
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: grab;
}

.track {
  top: 10px;
  background: #ccc;
  height: 5px;
  border-radius: 5px;
}

.noRatingsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  color: white;
}

.noRatingsText {
  font-size: 16pt;
  text-align: center;
  width: 600px;
}

.friendOptions {
  display: flex;
  flex-direction: row;
  gap: 100px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  z-index: 1000;
}

.usersOption {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.inviteIcon {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.inviteText {
  font-size: 12pt;
  color: #CCCCCC;
  margin: 0px;
}

.contentHeader {
  color: rgba(220,220,220,1);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 40px;
  text-align: left;
  width: 90%;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(220,220,220,0.6);
}

.contentHeader2 {
  color: rgba(220,220,220,1);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 40px;
  text-align: left;
  width: 90%;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(220,220,220,0.6);
  margin-top: -15px;
}

.contentHeader3 {
  color: rgba(220,220,220,1);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 0px;
  text-align: left;
  width: 90%;

}

.contentHeader:hover {
  color: white;
}

.box {
  width: 160px;
  height: 220px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.box2 {
  width: 160px;
  height: 220px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.productBox {
  width: 160px;
  height: 310px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
  z-index: 0;
}

.productBox:hover, .box:hover, .box2:hover {
  transform: scale(1.03);
}

.arrowContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;  
}

.arrowContainer:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.moreArrow {
  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  color: white;
}

.productImages {
  position: relative;
  z-index: 0;
}

.productImage {
  width: 160px;
  height: 240px;
  align-self: center;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  z-index: 0;
}

.categoryIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  filter: invert();
  position: absolute;
  top: 3px;
  left: 3px;
  background-color: rgba(255,255,255,0.5);
  border-radius: 50%;
}

.boxTitle {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  margin: 0 10px;
  height: 35px;
  text-align: center;
}

.userTitle {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  margin-top: 8px;
  text-align: center;
}

.productTitle {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding: 3px;
  margin: 0px;
  height: 35px;
  overflow: hidden;
}

.productRatingText {
  color: #cccccc;
  font-size: 14px;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.productRatingText2 {
  color: #cccccc;
  font-size: 12px;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.predictedContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
}

.predictedContainer2 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  margin-top: -3px;
  padding-left: 3px;
  padding-right: 3px;
}

.picksText {
  color: #cccccc;
  font-size: 12px;
  margin: 0 10px;
  text-align: center;
}

.image {
  width: 100%;
  height: 100%;
  margin-top: 0;
  border-radius: 5px;
  object-fit: cover;
}

.userImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 8px 10px 0 10px;
  align-self: center;
  cursor: pointer;
  object-fit: cover;
}

.userRatingCount {
  color: #cccccc;
  font-size: 12px;
  text-align: center;
  align-self: center;
}

.bioText {
  color: #cccccc;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  width: 90%;
  align-self: center;
}

.switchText {
  color: #cccccc;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  width: 80%;
  align-self: center;
}

.similarityText {
  color: #cccccc;
  font-size: 12px;
  text-align: center;
  align-self: center;
  height: 0px;
}

.similarityText2 {
  color: transparent;
  font-size: 12px;
  text-align: center;
  align-self: center;
  height: 0px;
}

.seeAllText {
  color: white;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.seeAllBox {
  width: 140px;
  height: 210px;
  margin: 0 8px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 50px;
}

.seeAllBoxUsers {
  width: 140px;
  height: 200px;
  margin: 0 8px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}

.seeAllBoxUsers2 {
  width: 140px;
  height: 180px;
  margin: 0 8px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}

.ratingGalleryContainer {
  display: flex;
  flex-direction: column;
}

.followText {
  color: white;
  font-size: 14px;
  text-align: center;
}

.followButton {
  background-color: var(--site-purple); /* Replace with actual purple color variable or value */
  border-radius: 10px;
  font-size: 16px;
  color: white;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  z-index: 10;
}

.followButton:hover {
  background-color: rgba(69, 4, 86, 0.8)
}

.favourites {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  height: 40px;
}

.favouritesText {
  color: #cccccc;
  font-size: 12px;
  text-align: center;
  max-width: 85%;
}

.faveCategory {
  width: 20px;
  height: 20px;
  object-fit: contain;
  filter: grayscale(1); /* Replace tintColor with filter property */
  /* Add other styles here */
}

.userClose {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
}

.headerContainer {
  padding-top: 40px;
  position: absolute;
  z-index: 9000;
  height: 80px;
  transform: translateY(0);
  background-color: var(--site-background); /* Replace with actual background color variable or value */
}

.iconContainer {
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  position: absolute;
  bottom: 2px;
  right: 5px;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  align-self: center;
  filter: grayscale(1); /* Replace tintColor with filter property */
  display: flex;
  justify-content: center;
}

.productScroll {
  display: flex;
  flex-direction: row;
  overflow-x: auto; /* Enables horizontal scrolling */
  gap: 10px; /* Adds some space between the scroll items */
  padding: 10px 0;
  margin-left: 40px;
  z-index: 0;
}

.productScroll::-webkit-scrollbar {
  display: none; /* Hides the scrollbar in WebKit browsers */
}

.productScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.feedHeader {
  display: flex;
  flex-direction: row;
    /* Sticky behavior */
    position: -webkit-sticky; /* Safari support */
    position: sticky;
    top: 70px; /* Sticks to the top when scrolling */
    z-index: 100; /* Ensure it stays on top of other content */
    background-color: var(
      --site-background
    ); 
    padding: 10px; /* Optional: Adds padding to the sticky element */
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-left: 34px;
    width: 900px;
}

.filterIconNew {
  width: 20px;
  height: 20px;
  color: #CCCCCC;
  cursor: pointer;
  margin-left: 60px;

}

