
.page {
    font-family: Arial, sans-serif;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1px;
    background-color: var(
        --site-background
      );
    color: white;
    padding-top: 60px;
  }

.header{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 950px;
}

.tabs {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.tabs button {
    background-color: rgb(51, 0, 77);
    color: rgb(255, 255, 255);
    border-radius: 5px;
}