.page_title {
  font-size: 36pt;
  font-weight: bold;
  color: white;
  margin-top: -20px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  background-color: var(--site-background);
  min-height: 100vh;
}

.backIconContainer {
  position: absolute;
  top: 15px;
  left: calc((50vw - 400px) + 40px);
}

.backArrow {
  color: white;
  font-size: 42px;
  cursor: pointer;
}

.registerForm {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: white;
  width: 700px;
  margin-left: 100px;
}

/* Label styling */
.registerForm label {
  display: flex;
  align-items: center; /* vertical alignment */
  justify-content: space-between;
  width: 300px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.formGroup {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.genderPrivacy {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  padding-top: 10px;
}

.countries {
  display: flex;
  flex-direction: row;
  gap: 100px;
  align-items: center;
  padding-top: 10px;
}

.privacy {
  color: white;
  width: 130px;
}

.privacy_hidden {
  color: white;
  width: 130px;
  visibility: hidden;
}

/* Input styling with fixed width */
.registerForm input,
.registerForm select,
.registerForm textarea {
  width: 80%; /* Adjust as needed. This minus the label width and padding should equal 100% */
  box-sizing: border-box;
  height: 35px;
  border-radius: 5px;
  padding-left: 10px;
}

/* Textarea specific */
textarea {
  resize: vertical;
  min-height: 150px;
}

/* Buttons */
.registerButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.registerButtons button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--site-purple);
  color: #fff;
  transition: background-color 0.2s;
  margin-left: -100px;
  margin-top: -50px;
  height: 40px;
  width: 200px;
}

.registerButtons2 button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--site-purple);
  color: #fff;
  transition: background-color 0.2s;
  margin-left: 200px;
  margin-top: 20px;
  height: 40px;
  width: 200px;
}

.registerButtons button:hover {
  background-color: #34003a;
}

.registerButtons2 button:hover {
  background-color: #34003a;
}

/* Username validation message */
.usernameValidationMessage {
  color: red;
  font-size: 0.8rem;
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
}

.Input {
  max-width: 400px;
  margin-left: auto;
}

.tabs {
  display: flex;
  justify-content: space-evenly; /* Distribute tabs evenly across the available space */
  width: 700px; /* Adjust based on container requirements */
  margin: 10px 0; /* Optional margin adjustment */
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
}

.tabButton {
  background: none; /* No button background */
  border: none; /* No button border */
  color: #444; /* Default text color */
  padding: 10px; /* Adjust padding as needed */
  cursor: pointer; /* Pointer cursor for buttons */
  outline: none; /* Remove default focus border */
  font-size: 16px; /* Adjust font size */
  position: relative;
  width: 33%;
}

.active {
  color: white;
}

.active::after {
  content: ""; /* Pseudo-element for the underline */
  position: absolute;
  left: 0;
  bottom: -5px; /* Adjust position if needed */
  width: 100%;
  height: 2px; /* Thickness of the underline */
  background-color: var(--site-purple);
}

.photo {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 50px;
  margin-left: 250px;
  display: flex;
}

.image_upload {
  margin-top: -50px;
  padding-bottom: 50px;
  margin-left: -100px;
}

.inputGroup2 {
  display: flex;
  flex-direction: column;
  margin-left: -100px;
}

.accountContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  background-color: var(--site-background);
  min-height: 100vh;
  align-items: center;
}

.logoutIcon {
  color: white;
  font-size: 42px;
  cursor: pointer;
}

.logoutText {
  color: #CCCCCC;
  font-size: 24px;
  margin-top: -15px;
}

