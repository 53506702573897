/* General container styles */
.ratingContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  /* Button to open modal */
  .ratingContainer button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
  }
  
  /* Modal background */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    cursor: default;
  }
  
  /* Modal content styling */
  .modalContent {
    background-color: rgb(5, 5, 5);
    border: 1px solid #b7adad; 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 650px;
    height: 600px;
    position: relative;
    z-index: 92000;

  }

  
  .closeIcon {
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;

  }
  
  .closeIcon:hover {
    color: rgb(204, 193, 193);
  }
  
  /* Styles for the stars container */
  .starsContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .star {
    cursor: pointer;
    transition: color 0.3s ease;
    width: 55px;
    height: 55px;
  }

  .highlightedStar {
    color: purple; /* Highlight color */
  }
  
  .star:hover {
    color: darkviolet;
  }
  
  /* Rating submit button */
  .modalContent button {
    background-color: var(--site-purple);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 60%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: 50px;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    margin-top: -30px;
  }

  .modalContent button:hover {
    background-color: rgba(86, 0, 107,0.8);
  }

  
  /* Title and rating display */
  .modalContent h1, .modalContent p {
    text-align: center;
    margin: 10px 0;
  }
  
  .modalContent h1 {
    font-size: 96px;
    height: 110px;
    margin-top: 0px;
  }



  .ratingOptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    align-items: center;
    align-self: center;
    align-content: center;
  }

  .wishlist, .skip {
    color: var(--site-purple);
    cursor: pointer;
    font-size: 48px;
  }

  .wishlist:hover, .skip:hover {
    color: rgba(86, 0, 107,0.8);
  }

  .ratingDescription {
    font-size: 20pt;
    color: white;
    text-align: center;
    font-style: italic;
  }

  .ratingTitle {
    font-size: 18pt;
    color: white;
    text-align: center;
    font-weight: bold;
    max-height: 35px;
    overflow: hidden;
  }

  .ratingOptions p {
    margin-top: -2px;
  }

  .reviewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .review {
    width: 80%;
    height: 100px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 15px;
    background-color: transparent;
    color: white;
    font-size: 16pt;
    border: 1px solid rgb(38, 37, 37);

  }

  .ratingOptions h3 {
    color: white;
    font-size: 14pt;
    margin-top: -5px;
  }

  .pencil {
    color: rgb(137, 0, 147);
    font-size: 16px;
    cursor: pointer;
  }

  .pencilContainer {
    position: absolute;
    left: 40px;
    top: 00px;
  }

  .pencil2 {
    color: grey;
    font-size: 20px;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
  }

  .pencilContainer2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 10px;
    top: -10px;
  }

  .pencilContainer2  :hover {
    color: white;
  }


  .yourRatingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    gap: 0px;
  }
  .ratingText {
    font-size: 20pt;
    color: white;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
  }

  .yourRating {
    font-size: 8pt;
    color: #CCCCCC;
    width: 100%;
    text-align: center;
    margin: 0px;
    padding: 0px;
    margin-top: -5px;
  }

  .rateText {
    font-size: 14px;
    color: grey;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
  }


  .selectedRating {
    color: white;
  }

  .submitText {
    padding-top: 20px;
    font-size: 16pt;
    color: white;
  }

  .wishlistText{
    color: white;
  }

  .wishlistContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .imageContainer {
    position: absolute;
    bottom: 70px;
    right: 80px;
  }

  .rateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .rateContainer :hover {
    color: white;
  }
  