/* General container styling */
.container {
  width: 200px;
  position: relative;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.containerS {
  width: 200px;
  position: relative;
  padding-bottom: 10px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

/* Dropdown button styling */
.dropdownButton {
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 15px;
  height: 40px;
  width: 200px;
  background-color: var(
    --site-search
  ); /* Define variable in :root or substitute with a hex value */
}

.dropdownButtonSearch {
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 15px;
  height: 60px;
  width: 80px;
  background-color: transparent;
  margin-top: 10px;
}

/* Dropdown button styling */
.dropdownButtonS {
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 15px;
  height: 40px;
  width: 140px;
  background-color: var(
    --site-search
  ); /* Define variable in :root or substitute with a hex value */
}


.dropdownButtonR {
  display: flex;
  align-items: flex-end;
  border: 0;
  border-radius: 15px;
  height: 100px;
  width: 100px;
  background-color: transparent; /* Define variable in :root or substitute with a hex value */
  cursor: pointer;
}

/* Text styling within the button */
.buttonText {
  padding-left: 10px;
  color: white;
  font-size: 10pt;
  overflow-y: hidden;
  height: 40px;
  align-content: center;
}

/* Text styling within the button */
.buttonText2 {
  padding-left: 10px;
  margin-right: 5px;
  color: white;
  font-size: 12pt;
}

/* Styles for the dropdown menu */
.dropdown {
  z-index: 5;
  width: 200px;
  background-color: var(
    --site-background
  ); /* Define variable in :root or substitute with a hex value */
  max-height: 600px;
  overflow-y: auto; /* To enable scrolling */
  position: absolute;
  left: 0px;
  top: 40px;
  border-radius: 10px;
}

/* Styles for the dropdown menu */
.dropdownR {
  z-index: 5;
  width: 200px;
  background-color: var(
    --site-background
  ); /* Define variable in :root or substitute with a hex value */
  max-height: 600px;
  overflow-y: auto; /* To enable scrolling */
  position: absolute;
  left: -90px;
  top: 100px;
  border-radius: 10px;
}

/* Styles for the dropdown menu */
.dropdownSearch {
  z-index: 5;
  width: 200px;
  background-color: var(
    --site-background
  ); /* Define variable in :root or substitute with a hex value */
  max-height: 600px;
  overflow-y: auto; /* To enable scrolling */
  position: absolute;
  left: -50px;
  top: 65px;
  border-radius: 10px;
}

/* Styles for the dropdown menu */
.dropdownS {
  z-index: 5;
  width: 140px;
  background-color: var(
    --site-background
  ); /* Define variable in :root or substitute with a hex value */
  max-height: 600px;
  overflow-y: auto; /* To enable scrolling */
  position: absolute;
  left: 30px;
  border-radius: 10px;
}

/* Dropdown item styling */
.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 50px;
  background-color: rgb(30,30,30);
  border: none;
  width: 200px;
  flex-direction: row;
  border: 1px solid rgb(50,50,50);
  border-radius: 10px;
}

.searchInput {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 50px;
  background-color: rgb(30,30,30);
  border: none;
  min-width: 200px;
  flex-direction: row;
  border: 1px solid rgb(50,50,50);
  border-radius: 10px;
  outline: none;
  color: white;
}

/* Dropdown item styling */
.dropdownItemS {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 50px;
  background-color: rgb(30,30,30);
  border: none;
  width: 140px;
  flex-direction: row;
  border: 1px solid rgb(50,50,50);
  border-radius: 10px;
}

.dropdownItemS:hover {
  background-color: rgb(40, 40, 40);
}


.dropdownItem:hover {
  background-color: rgb(40, 40, 40);
}

/* Styling for selected item */
.selectedItem {
  background-color: var(
    --site-purple
  ); /* Define variable in :root or substitute with a hex value */
}

/* Text styling within each dropdown item */
.itemText {
  margin-left: 5px;
  color: white;
  font-size: 12pt;
  background-color: transparent;
}

/* Checkbox styling */
.checkbox {
  position: absolute;
  right: 5px;
  height: 25px;
  width: 25px;
}

/* Arrow icon styling */
.arrow {
  position: absolute;
  right: 10px;
  font-size: 18pt;
}

/* Arrow icon styling */
.arrowSearch {
  position: absolute;
  right: 10px;
  font-size: 18pt;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-left: 1px solid rgba(255,255,255,0.1);
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.icon2 {
  width: 30px;
  height: 30px;
  object-fit: scale-down;
  filter: invert(1);
  position: static;
  background-color: transparent;
}

.filterSearch {
  font-size: 16pt;
  color: #CCCCCC;
}

.filterSearch:hover {
  color: white;
}

.arrowS {
  position: absolute;
  right: 50px;
  font-size: 18pt;
}

.arrow2 {
  position: absolute;
  right: 60px;
  font-size: 0pt;
}

/* Styles for the 'Select All' button */
.allButton2 {
  background-color: var(
    --site-purple
  ); /* Define variable in :root or substitute with a hex value */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  border-radius: 5px;
  cursor: pointer;
}

/* Submit button styling */
.submitButton {
  background-color: var(
    --site-purple
  ); /* Define variable in :root or substitute with a hex value */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  position: absolute;
  top: 620px; /* Adjust as needed */
  left: 50%; /* Centering the button */
  transform: translateX(-50%); /* Centering the button */
  z-index: 5000;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

.submitButtonR {
  background-color: var(
    --site-purple
  ); /* Define variable in :root or substitute with a hex value */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  position: absolute;
  top: 620px; /* Adjust as needed */
  left: 10px; /* Centering the button */
  transform: translateX(-50%); /* Centering the button */
  z-index: 5000;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}


/* General text styling */
.text {
  color: white;
  font-size: 16px;
  text-align: center;
}

/* Hidden styling */
.hidden {
  z-index: 4;
  width: 200vw;
  height: 100vh; /* Assuming you want to cover the full viewport height */
  background-color: transparent;
  border: none;
  position: fixed;
  top: 0;
  left: 0;
}

.categoryIcon {
  width: 30px;
  height: 30px;
  object-fit: scale-down;
  filter: invert(1);
  position: static;
  background-color: transparent;
}

.searchImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
  filter: invert(1);
  z-index: 5000;
}
