.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    background-color: var(
        --site-background
      );
    min-height: 80vh;
    overflow: hidden; /* Disables scrolling */
}

  .backArrow {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .headerInfo {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 120px;
    margin-top: 0px;
  }
  
  .profilePic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .username {
    font-size: 20px;
    font-weight: bold;
    margin-top: -5px;
    color: white;
  }
  
  .messagesList {
    height: 75vh;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    width: 750px;
    margin-top: 40px;
  }
  
  .backIconContainer {
    position: absolute;
    top: 15px;
    left: calc((50vw - 400px) + 40px);
  }
  
  .backArrow {
    color: white;
    font-size: 42px;
    cursor: pointer;
  }

  .messageContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .currentUserMessage {
    background-color: rgb(60, 0, 60);
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
    border-bottom-right-radius: 0;
    max-width: 60%;
    margin-left: auto;
    color: white;
    min-height: 30px;
    min-width: 100px;
  }

  
  .otherUserMessage {
    background-color: rgb(60, 60, 60);
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
    border-top-left-radius: 0;
    max-width: 60%;
    margin-right: auto;
    color: white;
    min-height: 30px;
    min-width: 100px;
  }
  
  
  .timestamp {
    text-align: center;
    color: grey;
    margin: 5px 0;
  }
  
  .inputContainer {
    display: flex;
    padding: 10px;
    gap: 0px;
    border-top: 1px solid grey;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .messageInput {
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 0 10px;
    color: white;
    width: 700px;
    margin-left: 30px;
    background-color: rgb(40,40,40);
    height: 50px;
  }
  
  .messageImage {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    margin-top: 20px;
    object-fit: contain;
    cursor: pointer;
  }

  .sendIcon {
    color: white;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    right: calc((50vw - 400px) + 60px);
    padding-top: 10px;
  }

  .backIconContainer {
    position: absolute;
    top: 15px;
    left: calc((50vw - 400px) + 40px);
  }
  
  .backArrow {
    color: white;
    font-size: 42px;
    cursor: pointer;
  }

  