.container {
  background-color: rgb(31, 29, 29);
  min-height: 100vh;
  width: 100%; /* Changed from 100vw to 100% to avoid potential overflow issues */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden; /* Ensures no horizontal overflow */
  background-image: url("https://s3.eu-west-2.amazonaws.com/rated.images/background.png");
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  max-width: 100%; /* Ensures the content doesn't exceed the viewport width */
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%; /* Make sure the page width does not exceed the viewport width */
  max-width: 1600px; /* Max width to control content width */
  margin-top: 100px;
}
.logoImage {
  width: 250px;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image fits well */
  padding-left: 20px;
}

.logoContainer {
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Near black background for the logo container */
  height: 80px; /* Increased height */
  width: 100%; /* Ensure it spans the full width */
  position: relative; /* Ensure proper positioning */
  display: flex; /* Using flex to align children inside it */
  justify-content: space-between; /* Distribute space between elements */
  align-items: center; /* Center items vertically */
  position: fixed; /* Fixed positioning */
}

.footer {
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Near black background for the logo container */
  height: 30px; /* Increased height */
  width: 100%; /* Ensure it spans the full width */
  position: fixed; /* Changed from relative to fixed */
  bottom: 0; /* Anchor it to the bottom */
  display: flex; /* Using flex to align children inside it */
  justify-content: flex-start; /* Distribute space between elements */
  align-items: center; /* Center items vertically */
  gap: 40px;
  left: 0; /* Ensure it covers from left to right */
  height: 20px;
}

.badgeImage {
  width: 240px;
  height: 120px;
  object-fit: contain;
  cursor: pointer;
}
.badgeImageApple {
  width: 210px;
  height: 120px;
  object-fit: contain;
  cursor: pointer;
}

.badgeImage:hover, .badgeImageApple:hover {
  scale: 1.1;
}

.badgeImage2 {
  margin-top: 7px;
  height: 80px;
  object-fit: contain;
  opacity: 0.8;
}
.badgeImageApple2 {
  margin-top: 20px;
  height: 55px;
  object-fit: contain;
  opacity: 0.8;
}

.badgeImage2:hover, .badgeImageApple2:hover {
  scale: 1.1;
}

.websiteLink2 {
  color: white;
  font-size: 16pt;
  background-color: rgba(86,0,107,0.6);
  width: 200px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgb(123, 115, 115);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 50px;
}

.websiteLink2:hover {
  background-color: rgba(86,0,107,1);
  scale: 1.05;
}

.buttonContainer2 {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none; /* Prevent interaction when hidden */
}

.buttonContainer2Visible {
  opacity: 1;
  pointer-events: auto; /* Allow interaction when visible */
}

.left {
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 50%;
}

.right {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.buttonContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.screenshot {
  width: 500px;
  height: 620px;
  object-fit: contain;
}

.textContainer h1 {
  font-size: 24px;
  color: white;
  font-weight: 600;
}

.text h2 {
  font-size: 24px;
  color: white;
  font-weight: 400;
  align-items: first baseline;
}

.text {
  color: white;
}

.socialLinks {
  display: flex;
  gap: 20px; /* Space between icons */
  padding: 30px;
  justify-content: flex-end; /* Center the icons */
  width: 100%; /* Ensure it spans the full width */
}

.socialIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  cursor: pointer;
}
.socialIcon:hover {
  scale: 1.1;
}

.textContainer .title {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 600px;
  text-align: center;
}

.proceedButton {
  margin-left: 150px;
}

.footer p {
  color: white;
  font-size: 12px;
}

.footer a {
  color: white;
  font-size: 12px;
}

.websiteLink {
  color: white;
  font-size: 18pt;
  background-color: rgba(86,0,107,0.8);
  width: 240px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid rgb(123, 115, 115);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}

.websiteLink:hover {
  background-color: rgba(86,0,107,1);
  scale: 1.05;
}

.headCaption {
  color: white;
  font-size: 3rem; /* Use relative units for responsiveness */
  margin: 0;
  padding-top: 30px;
  text-align: left; /* Center the hero text */
}

.subCaption {
  color: #CCCCCC;
  font-size: 1.5rem; /* Use relative units for responsiveness */
  margin: 0;
  padding-top: 20px;
  text-align: left; /* Center the sub-caption */
}

.notes {
  color: #CCCCCC;
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-top: -50px;
  text-align: center;
  font-style: italic;
}

.categoryImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
  filter: invert();
  opacity: 0.75;
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
  cursor: pointer;
}

.categoryContainer:hover {
  scale: 1.1;
}

.categoryText {
  color: #CCCCCC;
  font-size: 14px;
  text-align: center;
}

.categoryGallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  margin-top: 0px;
  flex-wrap: wrap;
  width: 100%;
}

.more {
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-left: 100px;
}

.information {
  padding: 10px;
  padding-top: 100px;
}

/* Info Container */
.infoContainer {
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1500px;
}

/* Info Container */
.infoContainerNew {
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1500px;
}



.infoMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 50px;
}

.infoMain2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}



.infoTitle {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 60px;
  color: #fff;
  flex: 1 1 50%;
  text-align: left;
}

.infoImage img {
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  width: 360px;
  object-fit: contain;
}

.infoImage2 img {
  border-radius: 10px;
  margin: 0 auto;
  object-fit: contain;
  height: 380px;
}

.infoImage4 img {
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  width: 550px;
  object-fit: contain;
}

.infoImage2  {
  display: flex;
  flex-direction: column;
}

.infoImage3  {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}


.imageCaption {
  font-size: 1.2em;
  color: #fff;
  margin-top: 10px;
  flex: 1 1 50%;
  padding: 0 20px;
  text-align: center;
  width: 400px;
  height: 100px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.0);
  border-radius: 10px;
}

.imageCaption2 {
  font-size: 28px;
  color: #fff;
  margin-top: 10px;
  flex: 1 1 50%;
  padding: 0 20px;
  text-align: left;
  width: 600px;
  height: 100px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.0);
  border-radius: 10px;
}

.infoRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 20px;
}

.infoText {
  font-size: 24px;
  color: #fff;
  margin-top: 10px;
  flex: 1 1 50%;
  padding: 0 20px;
  text-align: center;
  width: 1000px;
  padding-bottom: 400px;
}

.infoTextAbout {
  font-size: 1.2em;
  color: #fff;
  margin-top: 10px;
  padding: 0 20px;
  text-align: center;
  width: 800px;
}

/* Additional Sections */
 .aboutUs, .features {
  background: rgba(255, 255, 255, 0.1);
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.infoTitle, .aboutUs .infoTitle, .features .infoTitle {
  font-size: 2em;
  margin-bottom: 10px;
  color: #fff;
}

.infoText, .aboutUs .infoText, .features .infoText {
  font-size: 1.2em;
  color: #fff;
}

.infoText strong {
  display: block;
  font-size: 48px;
  color: #e6dcdc;
  margin-top: 20px;
}

.valueContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.07);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

.valueTitle {
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  flex: 1;
  padding-top: 30px;
}

.valueDescription {
  flex: 2;
  padding-left: 0px;
  font-size: 18px;
  line-height: 1.6;
  color: #FFFFFF;
  padding-top: 30px;
  width: 500px;
}

.visionText {
  flex: 2;
  padding-left: 0px;
  font-size: 18px;
  line-height: 1.6;
  color: #FFFFFF;
  padding-top: 30px;
  width: 1000px;
  text-align: center;
}

.profileImage {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto;
}

.predictedImage {
  transform: rotate(-90deg);
  transform-origin: center;
  height: 500px;
  object-fit: contain;
}

.mathsImage {
  width: 350px;
  height: 400px;
  object-fit: contain;
  
}

.cloudImage {
  height: 700px;
  object-fit: contain;
  
}

.section {
  display: flex;
  align-items: center;
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  width: 200px;
  padding-top: 20px;
}

.textContainer {
  padding: 20px; /* Add padding to prevent text from touching edges */
  width: 100%; /* Ensure it uses available width */
  max-width: 600px; /* Control maximum width for better readability */
}

.stepTitle {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.stepDescription {
  font-size: 20px;
  color: #CCCCCC;

}

.alternate {
  flex-direction: row-reverse;
}

.mainScreenshot {
  padding-top: 40px;
  height: 600px;
  object-fit: contain;
}

.right {
  width: 50%;
}

.privacy{
  cursor: pointer;
}

.contact{
  cursor: pointer;
}

.values {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 20px;
}

