
.submit-product-container {
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .submit-product-header {
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  
  .submit-product-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .submit-product-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .submit-product-fields label {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  input, textarea {
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-product-buttons {
    display: flex;
    justify-content: center;
    
    gap: 1rem;
  }
  
  .submit-product-buttons button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #10001d;
    color: #fff;
    transition: background-color 0.2s;
  }
  
  .submit-product-buttons button:hover {
    background-color: #34003a;
  }
  
  .keywords-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    height: 100px; /* adjust as needed */
    overflow-y: scroll;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0.5rem;
  }
  
  .keyword-bubble {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #6600ff;
    color: #fff;
    border-radius: 15px;
    height: 15px;
  }
  
  .remove-keyword {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    
    cursor: pointer;
  }