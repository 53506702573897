.page_title {
    font-size: 36pt;
    font-weight: bold;
    color: white;
    margin-top: -20px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    background-color: var(--site-background);
  }
  
  .backIconContainer {
    position: absolute;
    top: 15px;
    left: calc((50vw - 400px) + 40px);
  }
  
  .backArrow {
    color: white;
    font-size: 42px;
    cursor: pointer;
  }
  
  .fileInput {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
}

.customFileButton {
    /* Style this button as you would like your file input to appear */
    padding: 10px 20px;
    background-color: var(--site-purple);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: -10px;
    width: 200px;
}
  .errorList {
    margin-top: 20px;
  }
  
  .csvStructure {
    margin-top: 20px;
    text-align: center;
    color: white;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
  }
  
  .csvImage {
    max-width: 100%;
    height: auto;
  }

  .dropdownContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding-top: 20px;
  }

  .introText {
    font-size: 12pt;
    color: white;
    text-align: center;
    margin-top: 20px;
  }