
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  background-color: var(
    --site-background
  );
  padding-top: 100px;
}

.all_filters {
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(14, 14, 17);
  width: 900px;
  margin-top: 50px;
}

.top_filters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 40px;
  max-width: 1000px;
  margin-top: -30px;
  position: fixed;
  height: 80px;
  background-color: var(
    --site-background
  );
  z-index: 5000;
  width: 1200px;
  padding-left: 10px;
}

.searchInput {
  background-color: rgb(40, 40, 40);
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  outline: none;
  width: 200px;
  font-size: 18px;
  margin-top: -10px;
  
}

.header_filters {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  height: 100px;
  padding-left: 10px;
}


.bottom_row_filters {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 20px;
}

.horizontal_slider {
  width: 200px;
  margin-top: -5px;
  margin-left: -70px;
}

.slider_label {
  position: absolute;
  margin-top: -25px;
  color: white;
  margin-left: -20px;
}

/* Container that wraps both the input and the dropdown */
.dropdown_container {
  position: relative; /* This makes the child absolute elements positioned relative to this container */
  width: 300px;
}


/* Base dropdown styling */
.dropdown {
  list-style: none;
  padding: 0;
  background: rgb(30,30,30); /* Adjust as needed */
  color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 300px; /* You can set max height */
  overflow-y: auto; /* Makes it scrollable */
  position: absolute; /* Adjust positioning as needed */
  top: 100%; /* Positions the dropdown right below the input box */
  left: 50px;
  width: 250px; /* Matches the input width */
  z-index: 100000; /* Ensure it's above other content */
  margin-left: -40px;
}

/* Individual list items */
.dropdown li {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
  justify-content: space-between; /* Aligns content to the sides */
  align-items: top; /* Vertically centers the content */
  min-height: 30px;
  max-height: 80px; /* Fixed height for each item */
  color: white;
  overflow-y: hidden;
}

/* Hover state for list items */
.dropdown li:hover {
  background-color: #423f3f;
}

/* Styling the details part of each list item */
.search_details {
  display: flex;
  justify-content: top;
  align-items: top;
  width: 100%;
  color: white;
}

/* Styling the text part within details */
.search_details_name {
  margin-right: auto; /* Pushes everything else to the right */
  font-size: 0.9em; /* Adjust size as needed */
  color: #ffffff;
}


.filters_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  width: 80%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  max-height: 80px;
  overflow-y: auto;
  padding-bottom: 20px;
}

.keyword_bubble {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(
    --site-purple
  );
  color: #fff;
  border-radius: 15px;
  height: 15px;
}

.bubble_button {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 40px;
  width: 1000px;
}

.gallery_card {
  width: 300px;
  height: 300px;
  border-radius: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  color: white;
  justify-content: flex-start;
  cursor: pointer;
}

.cardTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 600px;
}

.categoryIcon {
  width: 35px;
  height: 35px;
  position: absolute;
  left: 5px;
  top: 5px;
  filter: invert(1);
}

.gallery_card:hover {
  transform: scale(1.05); /* Small zoom effect on hover */
}

.userImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: 10px;
  object-fit: cover ;
  background-color: black;
}


.gallery_card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardMiddle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
  height: 150px;
}

.username {
  font-size: 16pt;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.userInfo {
  font-size: 12pt;
  align-items: flex-start;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #CCCCCC;
  width: 90%;
}

.userInfo2 {
  font-size: 12pt;
  align-items: flex-start;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #CCCCCC;
  width: 90%;
  font-style: italic;
  padding-top: 3px;
  height: 40px;
}

.similarity {
  font-size: 12pt;
  margin: 0;
  color: #CCCCCC;
  position: absolute;
  left: 10px;
  gap: 10px;
  padding: 0;
}

.similarityScore {
  font-size: 20pt;
  margin: 0;
  color: white;
  gap: 0px;
  padding: 0;
}

.similarityScore2 {
  font-size: 14pt;
  margin: 0;
  color: #CCCCCC;
  gap: 0px;
  padding: 0;
}

.similarityLabel {
  font-size: 10pt;
  margin: 0;
  color: #CCCCCC;
  gap: 0px;
  padding: 0;
}

.ellipsis {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  color: #CCCCCC;
  cursor: pointer;
  z-index: 1000;
}

.ellipsis:hover {
  color: white;
}

.cardRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
  padding-bottom: 30px;
  gap: 20px;
  padding-top: 10px;
}

.similarityText {
  font-size: 12pt;
  color: #CCCCCC;
  padding-top: 10px;
}

.card_relations {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  padding-bottom: 5px;
}

.friend_request {
  text-decoration: underline;
  cursor: pointer;
}

.follow {
  text-decoration: underline;
  cursor: pointer;
}

/* Category Page */
.gallery_card::before {
  /* content: "#" attr(data-rank); */
  /* Fetch the rank number from the data attribute */
  position: absolute; /* Absolutely position the rank number within the card */
  top: 0px; /* Adjust as necessary */
  left: 0px; /* Adjust as necessary */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  color: #f7f7f7; /* White text color */
  padding: 5px 5px; /* Some padding for aesthetics */
  border-radius: 3px; /* Rounded corners */
  z-index: 10; /* Ensure it's above other content */
  font-size: 1em; /* Adjust as necessary */
}

.cardRelations {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  padding-bottom: 5px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 15px;
}

.skip_container {
  width: 200px;
  height: 40px;
  background-color: rgb(64, 61, 61);
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

.friendsText {
  font-size: 12pt;
  color: #ffffff;
}

.friends_container {
  width: 200px;
  height: 40px;
  background-color: var(
    --site-purple
  );
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.friends_container:hover {
  background-color: rgb(74, 6, 91);
}

.skip_container:hover {
  background-color: rgb(47, 46, 48);
}

.min_raters_input {
  width: 140px;
  background-color: var(
    --site-search
  );
  color: rgb(255, 255, 255);
  border: none;
  outline: none;
  height: 25px;
  border-radius: 15px;
  margin-top: -10px;

}

.product_search_input {
  width: 180px;
  height: 25px;
  background-color: var(
    --site-search
  );
  color: white;
  border: none;
  outline: none;
  border-radius: 15px;
  font-size: 12pt;
  padding-left: 10px;
  margin-left: 10px;
  
}

.sortButton {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 20pt;
  padding: 10px;
  z-index: 1000;
  margin-left: -55px;
  margin-top: -15px;
}

.dropdownUser {
  margin-left: -25px;
  margin-right: -30px;
}
.thumb {
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: grab;
}

.track {
  top: 10px;
  background: #ccc;
  height: 5px;
  border-radius: 5px;
}

.sliderContainer {
  margin-top: -20px;
  margin-left: -20px;
}

.add_filter_button {
  background-color: var(
    --site-purple
  );
  color: white;
  border: none;
  outline: none;
  border-radius: 15px;
  cursor: pointer;
  padding: 5px;
  margin-top: 0px;
  width: 100px;
  height: 50px;
}

.filterButton {
  background-color: rgba(86, 0, 107, 0.6);
  color: white;
  border: 1px solid rgb(194, 185, 185);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  font-size: 24pt;
  padding: 10px;
}

.filterContainer{
    position: fixed;
    right: calc((50vw - 600px) + 0px);
    bottom: 200px;
}

.inviteContainer{
  position: absolute;
  right: calc((50vw - 400px) + 40px);
  top:14px;
  display: flex;
  flex-direction: column;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.copyMessage{
  position: absolute;
  right: calc((50vw - 400px) + 40px);
  top:50px;
  color: white;
}

.addUserButton {
  color: white;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}

.inviteText {
  font-size: 10pt;
  color: #CCCCCC;
  margin: 0;
  padding: 0;
}

.resetFilter {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.similarityInfo {
  font-size: 8pt;
  color: #CCCCCC;
  position: absolute;
  top: 60px;
  gap: 0px;
  padding: 0;
}

.noUsers {
  font-size: 14pt;
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: normal;
  width: 500px;
  text-align: center;
}

.note {
  font-size: 10pt;
  color: #CCCCCC;
  margin: 0;
  padding-left: 20px;
  margin-bottom: 20px;
}

.dropdownCategory {
  margin-left:  25px;
}

.newFilterIcon {
  width: 25px;
  height: 25px;
  margin-left: 120px;
  cursor: pointer;
  color: #CCCCCC;
  margin-top: -5px;
}
