.socialButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  gap: 50px;
}




/* Assuming you're using CSS Modules. If not, you might need to adjust the class names for uniqueness */

.loginText,
.registerText,
.usernameText,
.logoutText {
  /* Your desired styles for these texts */
  cursor: pointer;
}

.loginText {
  height: 50px;
  width: 210px;
  text-align: left;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 18px;
  border-radius: 5px;
}

.loginText:hover {
  background-color: rgb(40, 40, 40);
  scale: 1.05;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
}

.loginPopup {
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 320px;
  padding: 20px;
  background-color: rgb(10, 10, 10);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(82, 76, 76);
  border-radius: 10px;
  z-index: 9999;
}

.loginPopup input {
  width: 300px;
  background-color: transparent;
  color: white;
  margin-left: 10px;
  margin-bottom: 20px;
  height: 30px;
  border: 1px solid rgb(61, 57, 57);
}

.errorText {
  color: red;
  cursor: pointer;
  height: 10px;
  margin: 0;
  padding: 0;
}

.loginProfilePicHeader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 50px;
  cursor: pointer;
} 


.loginProfilePicHeader:hover {
  scale: 1.04;
}


.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  margin-top: 5px;
}

.loginButton {
  background-color: var(--site-purple);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 300px;
  border-radius: 10px;
  height: 40px;
  margin-top: 0px;
}

.loginButton2 {
  background-color: var(--site-purple);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 300px;
  border-radius: 10px;
  height: 40px;
  margin-top: 20px;
  margin-left: 20px;
}

.loginButton:hover, .loginButton2:hover {
  background-color: rgb(137, 0, 147);
  scale: 1.04;
}

.loginIcon {
  width: 30px;
  height: 30px;
  padding-right: 30px;
  margin-left: -70px;
  color: white;
  cursor: pointer;
}

.loginIcon:hover {
  scale: 1.04;
}

.headerTitle {
  margin-top: -10px;
  margin-bottom: 40px;
  color: white;
  font-weight: normal;
  font-size: 28px;
}

.closeIcon {
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #CCCCCC;
}

.leftIcon {
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  left: 15px;
  color: #CCCCCC;
}

.closeIcon:hover {
  color: white;
}

.oneClick {
  text-align: center;
  font-size: 14px;
  color: #CCCCCC;
}

.googleContainer, .appleContainer, .facebookContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: 300px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.loginLogo {
  width: 30px;
  height: 30px;
  object-fit: cover;
  padding-left: 20px;
  padding-right: 30px;
}

.googleIcon, .facebookIcon, .appleIcon {
  width: 20px;
  height: 20px;
  padding-right: 30px;
  padding-left: 20px;
}

.googleContainer:hover, .appleContainer:hover, .facebookContainer:hover {
  scale: 1.04;
  filter: brightness(1.2);
  cursor: pointer;
}


.alreadyText {
  color: #CCCCCC;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.alreadyText:hover {
  color: white;
  text-decoration: underline;
}

.loginEntry {
  margin-top: 40px;
}

.orContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: rgba(255,255,255,0.5); /* or any color that matches your design */
  margin: 0 10px;
  border: none; /* Remove any border style */
  min-width: 120px; /* Ensure minimum width so it doesn't collapse */

}

.orText {
  font-size: 14px;
  color: #CCCCCC; /* or any color that matches your design */
  white-space: nowrap; /* Prevent wrapping */
}

.googleText, .facebookText, .appleText {
  color: #CCCCCC;
  font-size: 14px;
  cursor: pointer;
}