.page_title {
    font-size: 36pt;
    font-weight: bold;
    color: white;
    margin-top: -20px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    background-color: var(--site-background);
  }
  
  .backIconContainer {
    position: absolute;
    top: 15px;
    left: calc((50vw - 400px) + 40px);
  }
  
  .backArrow {
    color: white;
    font-size: 42px;
    cursor: pointer;
  }
  
  .infoHeaderLeft {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .infoHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .userImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .text {
    font-size: 16px;
    color: #666;
    margin-left: -20px;
  }
  
  .bioText {
    color: #ffffff;
    line-height: 1.5;
  }
  
  .link {
    color: #2980b9;
    text-decoration: none;
  }
  
  .bio {
    background-color: transparent;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    border-radius: 8px;
  }