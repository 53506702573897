.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 950px;
    background-color: var(
      --site-background
    );
    min-height: 100vh;
    padding-top: 100px;
  }
  
  .searchBar {
    background-color: rgb(40, 40, 40);
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
    outline: none;
    width: 300px;
    font-size: 18px;
    margin-left: 50px;
  }
  
  .messageItem {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    align-items: center;
    height: 80px;
    padding-left: 30px;
  }
  
  .profilePhoto {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0.5px solid black;
  }
  
  .messageInfo {
    flex: 1;
    justify-content: center;
  }

  .messageContent {
    margin-top: 20px;
  }
  
  .name, .lastMessage, .lastMessagePrefix {
    font-weight: bold;
    font-size: 16px;
    color: white;
    padding-left: 10px;
  }

  .lastMessagePrefix {
    font-size: 14px;
    margin-left: -10px;
    color: #CCCCCC;
  }
  
  .lastMessage {
    font-size: 14px;
    color: #CCCCCC;
    padding-top: 10px;
    max-width: 500px;
    white-space: nowrap;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .unreadDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: purple;
    align-self: flex-end;
    margin-right: 5px;
  }
  
  .statusIndicator {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
    margin-left: auto;
    margin-right: 50px;
  }
  
  .requestOptions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: absolute;
    right: 5px;
  }
  
  .searchHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .friendRequests {
    background-color: var(--site-search);
    height: 32px;
    border-radius: 15px;
    width: 35%;
    align-content: center;
    justify-content: center;
    align-self: center;
    margin-left: 15px;
  }
  
  .text {
    color: white;
    text-align: center;
  }
  
  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    gap: 30px;
    padding-bottom: 10px;
  }
  
  .button {
    height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  
  .buttonText {
    color: white;
    font-size: 14px;
  }
  
  .activeTab {
    border-bottom: 2px solid purple;
  }
  .timestamp {
    font-size: 12px;
    color: grey;
    text-align: center; /* Center-align the text */
    width: 100%; /* Allow the text to take the full width of its container */
    white-space: normal; /* Ensure that text wrapping is allowed */
  }
  
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px; /* Maintain spacing between elements */
    width: 90px; /* You might need to adjust this based on your design */
  }

  .eye {
    width: 25px;
    height: 25px;
  }

  .noMessages {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    margin-top: 50px;
  }