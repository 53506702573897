.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    align-items: center;
    z-index: 50000;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
  }
  
  .tutorialContent {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    width: 80%;
    max-width: 600px;
    z-index: 5000;
    border: none;
    display: flex;
    flex-direction: column;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .tutorialText {
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 0;
    height: 120px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pageIndicatorContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .pageIndicator {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #bbb;
    margin: 0 5px;
  }
  
  .activePageIndicator {
    background-color: #fff;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .navButton {
    background-color: var(--site-purple); /* Use CSS variable or hardcode your color */
    padding: 10px;
    border-radius: 5px;
    min-width: 250px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border: none;
  }
  
  .navButtonText {
    color: #fff;
    font-size: 16px;
  }