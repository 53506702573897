.container {
  background-color: rgb(
    31,
    29,
    29
  ); /* Dark background for the outer container */
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent overflow to ensure proper visualization */
  background-image: url("https://s3.eu-west-2.amazonaws.com/rated.images/background.png");
  background-size: cover; /* Cover ensures the image covers the entire div without repeating */
  background-position: top; /* Center the background image */
  background-attachment: fixed; /* Fixed background */
}

.page {
  /*background-color: transparent; /* Ensuring the .page doesn't overwrite the .container's color */
  display: flex;
  flex-direction: column;
  width: 100%; /* Use 100% width for full coverage */
  margin-top: 30px;
}

.logoImage {
  width: 100px;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image fits well */
  padding-left: 5px;
}


.logoContainer {
  background-color: rgba(14, 0, 14,0.6); /* Near black background for the logo container */
  width: 100%; /* Ensure it spans the full width */
  position: relative; /* Ensure proper positioning */
  display: flex; /* Using flex to align children inside it */
  justify-content: space-between; /* Distribute space between elements */
  align-items: center; /* Center items vertically */
  height: 40px; /* Fixed height for the logo container */
}

.footer {
  background-color: rgba(0, 0, 0,0.4); /* Near black background for the logo container */
  width: 100%; /* Ensure it spans the full width */
  position: fixed; /* Changed from relative to fixed */
  bottom: 0; /* Anchor it to the bottom */
  display: flex; /* Using flex to align children inside it */
  justify-content: flex-start; /* Distribute space between elements */
  align-items: center; /* Center items vertically */
  gap: 20px;
  left: 0; /* Ensure it covers from left to right */
  height: 20px;
}

.badgeImage {
  width: 200px;
  height: 60px;
  object-fit: contain;
}
.badgeImageApple {
  width: 155px;
  height: 60px;
  object-fit: contain;
  margin-left: -20px;
}


.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageGallery {
  display: flex;
  justify-content: center;
}

.screenshot {
  width: 180px;
  height: 320px;
  object-fit: contain;
}

.container h2 {
  font-size: 18pt;
  color: rgb(255, 255, 255);
  font-weight: 400;
  align-items: first baseline;
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.socialLinks {
  display: flex;
  gap: 10px; /* Space between icons */
  width: 150px;
}

.socialIcon {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.title{
  margin-top: 60px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  gap: 0px;
  margin-top: 10px;
  width: 90%;
  color: #CCCCCC;
}

.stepTitle {
  font-size: 18pt;
  color: rgb(255, 255, 255);
  font-weight: 400;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.stepDescription {
  font-size: 11pt;
  color: #CCCCCC;
  font-weight: 400;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.proceedButton {
  margin-left: 150px;
}

.footer p {
  font-size: 6pt;
  color: rgb(255, 255, 255);
}

.footer a {
  font-size: 6pt;
  color: rgb(255, 255, 255);
}

.privacy .contact {
  font-size: 6pt;
  color: rgb(255, 255, 255);
}

.headCaption {
  color: white;
  font-size: 26px;
  text-align: center;
  align-self: center;
  font-weight: bold;
  margin-top: 60px;
}

.subCaption {
  color: #CCCCCC;
  font-size: 14px;
  margin: 0;
  width: 90%;
  text-align: center;
  margin-top: -10px;
  padding-bottom: 20px;

}

.notes {
  color: #CCCCCC;
  font-size: 12px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-style: italic;
  padding-bottom: 10px;
}

.categoryImage {
  width: 25px;
  height: 25px;
  object-fit: contain;
  filter: invert();
}

.categoryGallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  cursor: pointer;
  gap: 0px;
}

.categoryContainer:hover {
  scale: 1.1;
}

.categoryText {
  color: #CCCCCC;
  font-size: 6px;
  text-align: center;
}



.more {
  color: #CCCCCC;
  font-size: 10px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.pageTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}


/* Info Container */
.infoContainer {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.infoMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.infoMain2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}


.infoTitle {
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
  margin-top: 30px;
}

.infoImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infoImage img, .infoImage2 img {
  border-radius: 10px;
  object-fit: contain;
  width: 90%;
}

.infoImage2  {
  display: flex;
  flex-direction: row;
}

.infoImage3  {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}


.imageCaption {
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
  flex: 1 1 50%;
  padding: 0 20px;
  text-align: center;
  width: 75%;
}

.imageCaption3 {
  font-size: 16px;
  color: #fff;
  margin-top: -20px;
  flex: 1 1 50%;
  padding: 0 20px;
  text-align: center;
  width: 75%;
}


.infoText {
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
  flex: 1 1 50%;
  padding: 0 20px;
  text-align: center;
  width: 1000px;
}

.right {
  position: absolute;
  opacity: 0.5;
}

.information {
  margin-top: 30px;
  margin-bottom: 30px;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mathsImage {
  width: 70%;
  height: auto;
  object-fit: contain;
  border-radius: 20px;
}

.cloudImage {
  height: 350px;
  object-fit: contain;
  border-radius: 20px;
}

.valueTitle {
  font-size: 32px;
  color: #fff;
  text-align: center;
  margin-top: 30px;
}

.valueDescription {
  font-size: 14px;
  color: #CCCCCC;
  text-align: center;
  margin-top: 10px;
  padding: 0 20px;
  width: '90%';
}

.valueContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.07);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  width: 80%;
}

.end {
  height: 100px;
}

.infoText {
  margin-top: 40px;
  font-size: 18px;
  color: #fff;
  width: 80%;
}

