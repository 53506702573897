.blogPost {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #CCCCCC;
  }
  
  .blogPost h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: white;
    width: 700px;
    margin-left: 50px;
  }
  
  .blogPost p {
    margin-bottom: 15px;
    width: 700px;
    margin-left: 50px;
  }

  .blogPost h2 {
    margin-bottom: 15px;
    width: 700px;
    margin-left: 50px;
    color: white;
  }