.tagInput {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-top: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.tagInput button {
  background-color: rgb(52, 1, 52);
  color: white;
  cursor: pointer;
}

.input_dropdown {
  display: flex;
  flex-direction: column;
  width: 120px;
  position: relative; /* Add this line */
  border-radius: 10px;
  height: 40px;
  margin-left: 70px;
}
.customDropdown {
  margin-top: 50px;
  position: absolute; /* Add this line */
  width: 300px; /* Match the width of the input field */
  max-height: 300px;
  background-color: rgb(30, 30, 30);
  color: white;
  z-index: 500000; /* Ensure dropdown is on top of other elements */
  flex-shrink: 0;
  flex-grow: 0;
  overflow-y: scroll;
  margin-left: -60px;
  border-radius: 10px;
}

.dropdownItem {
  padding: 8px 10px;
  color: white;
  border: 1px solid #474141;
  cursor: pointer;
  background-color: rgb(30, 30, 30);
  border-radius: 10px;
}

.dropdownItem:hover {
  background-color: #272424;
}

.show_more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.show_more button {
  background-color: rgb(51, 0, 77);
  color: white;
  width: 120px;
  height: 40px;
  border-radius: 5%;
  cursor: pointer;
}



.keyword_input {
  width: 250px;
  height: 30px;
  background-color: var(--site-search);
  color: white;
  border: none;
  outline: none;
  border-radius: 15px;
  font-size: 12pt;
  margin-left: -60px;
}

.search_input {
  width: 240px;
  height: 24px;
  background-color: var(--site-search);
  color: white;
  border: none;
  outline: none;
  border-radius: 15px;
  font-size: 12pt;
  margin-right: -20px;
}

.page_title {
  font-size: 36pt;
  font-weight: bold;
  color: white;
  margin-top: -20px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  background-color: var(--site-background);
}

.backIconContainer {
  position: absolute;
  top: 15px;
  left: calc((50vw - 400px) + 40px);
}

.backArrow {
  color: white;
  font-size: 42px;
  cursor: pointer;
}

.filterButton {
  background-color: rgba(86, 0, 107, 0.6);
  color: white;
  border: 1px solid rgb(194, 185, 185);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  font-size: 24pt;
  padding: 10px;
  z-index: 10000;
}

.filterContainer{
  position: fixed;
  right: calc((50vw - 400px) + 30px);
  top: 110px;
  z-index: 5000;
}

h1 {
  text-align: center;
}

/*Category Page  Only */
.filters-horizontal .horizontal-slider {
  width: 200px;
  max-width: 200px;
  margin: 0;
  padding: 0;
  height: 25px;
}

/* Buddy Finder */
.rating-section .horizontal-slider {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 25px;
}

/* Buddy Finder */
.slider-scale {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  margin-top: -8px;
}

/* Buddy Finder & Category Page */
.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff; /* White text */
  margin-top: 0px;
  overflow: hidden;
}

.card {
  width: 1000px;
  height: 140px;
  margin-bottom: 10px;
  border-top: 1px solid #484646;
  padding: 5px;
  display: flex;
  flex-direction: column;
  position: relative; /* Add this line */
}

.galleryCard {
  display: flex;
  flex-direction: row;
  width: 950px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Aligns items to the top */
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.titleBlock {
  font-weight: normal;
  font-size: 14px;
  width: 500px;
  margin: 0;
  padding: 0;
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits text to two lines */
  -webkit-box-orient: vertical;
  gap: 10px;;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.title{
  color: white;
  font-size: 18px;
  margin: 0;
  padding: 0;
  width: 300px;
}

.secondary{
  color: #CCCCCC;
  font-size: 14px;
  margin: 0;
  padding: 0;
  margin-top: 3px;
  padding-left: 7px;
}

.ratingBlock {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  height: 60px;
  width: 100px;
}

.starIcon {
  color: purple; /* Gold color */
  font-size: 18px;
  padding-right: 5px;
}

.averageRating {
  font-size: 24px;
}

.numberRatings {
  font-size: 16px;
  color: #cccccc;
  margin-top: -15px;
  font-weight: normal;
}

.productImage {
  width: 100px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.ratersContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  bottom: 5px;
  left: 450px;
  width: 450px;
  position: absolute;
}

.userCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.userPhoto {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  background-color: black;
}

.userRating {
  font-size: 12px;
  color: #CCCCCC;
}

.ratingDropdown {
  align-self: flex-end;
  margin-top: 20px;
}

.noResults {
  text-align: center;
}

.middleCardContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 120px;
  margin-right: 20px;
  margin-left: 10px;
}

.card24::before {
  content: "#" attr(data-rank);
  position: absolute; /* Absolutely position the rank number within the card */
  top: 0px; /* Adjust as necessary */
  left: 0px; /* Adjust as necessary */
  background-color: rgba(12, 12, 12, 0.6); /* Semi-transparent black background */
  color: #f7f7f7; /* White text color */
  padding: 5px 5px; /* Some padding for aesthetics */
  border-radius: 3px; /* Rounded corners */
  z-index: 1; /* Ensure it's above other content */
  font-size: 1em; /* Adjust as necessary */
}

/* Other Styles */
.button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.table-container {
  max-height: 500px;
  overflow-y: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.buttons-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.remove-icon {
  position: static;
  margin-right: 15px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-filter-button {
  position: absolute;
  right: 6%;
}

.filter-info {
  margin-top: 0rem;
  font-size: 0.9em;
  color: #666;
  text-align: center;
}

.keywords_list {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  width: 600px;
  justify-content: center;
  align-items: center;
  max-height: 80px;
  overflow-y: auto;
}

.keywords-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0px;
  max-width: 300px; /* or whatever maximum width you prefer */
}

.keywordBubble {
  background-color: var(--site-purple);
  padding: 0px;
  margin: 3px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  width: fit-content;
  color: white;
  padding: 10px;

}

.keywordBubbleButton {
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 10px;
}

.filters-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 1000px;
  height: 200px;
  margin-right: auto;
  margin-left: auto;
}

.product-filters,
.user-filters {
  border: 6px solid #23002b;
  background-color: rgb(15, 15, 15);
  padding: 5px;
  margin: 0px;
  width: 47%; /* Or whatever width you find visually appealing */
  height: 200px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 10px;
}

h2 {
  margin-top: 0px;
  padding: 0px;
  margin-bottom: 10px;
  text-align: left;
}

select {
  margin-left: 5px;
  margin-right: 10px;
}

.reset-button {
  margin-left: 50px;
  width: 300px;
  height: 30px;
}

.buddy-count {
  text-align: center;
}

.rating-filters {
  align-items: center !important;
  gap: 10px !important;
  width: 80% !important;
}

.giveRatingContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  bottom: 12px;
  left: 280px;

}

.ratingRangeLabel {
  color: white;
  font-size: 16pt;
}

.ratingSlider {
  width: 500px;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: center;
  width: 50px;
  color: white;
}

.checkboxFilters {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.checkboxFilters input[type="checkbox"] {
  -webkit-appearance: none; /* Remove default styling for WebKit/Blink browsers */
  -moz-appearance: none; /* Remove default styling for Firefox */
  appearance: none;
  width: 25px; /* Checkbox size */
  height: 25px; /* Checkbox size */
  background: rgb(49, 47, 47); /* Background color */
  border-radius: 3px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  position: relative;
  border: 1px solid rgb(152, 145, 145);
}

.checkboxFilters input[type="checkbox"]:checked {
  background-color: purple; /* Background color when checked */
  border-color: purple; /* Border color when checked */
}

.checkboxFilters input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.sortButton {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 20pt;
  padding: 10px;
  z-index: 1000;
  margin-left: -55px;
  margin-top: -15px;
}

.searchInput {
  display: flex;
  align-items: center;
  margin-top: -10px;
  border: none;
  border-radius: 15px;
  height: 25px;
  width: 200px;
  color: white;
  font-size: 12pt;
  outline: none;
  background-color: var(
    --site-search
  ); /* Define variable in :root or substitute with a hex value */
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
  background-color: rgb(14, 14, 17);
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
  border-radius: 20px;
  margin-bottom: 20px;
}

.filtersTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0px;
  z-index: 1000;
  margin-left: 0px;
  /* Sticky behavior */
  position: -webkit-sticky; /* Safari support */
  position: sticky;
  top: 70px; /* Sticks to the top when scrolling */
  z-index: 100; /* Ensure it stays on top of other content */
  background-color: var(
    --site-background
  ); 
  margin-top: -40px;
  padding-top: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.product_filters_bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 20px;
  padding-left: 10px;
}

.yearFilters, .locationFilters {
  display:  flex;
  flex-direction: row;
  gap: 20px;
}



.checkboxFilters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.min_raters_input {
  width: 100px;
  height: 24px;
  background-color: var(--site-search);
  color: white;
  border: none;
  outline: none;
  border-radius: 15px;
  font-size: 12pt;
  margin-left: 35px;
}

.min_raters_input2 {
  width: 100px;
  height: 24px;
  background-color: var(--site-search);
  color: white;
  border: none;
  outline: none;
  border-radius: 15px;
  font-size: 12pt;
}

.filterButton2 {
  color: #CCCCCC;
  width: 25px;
  height: 25px;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 8px;
  cursor: pointer;
}

.productSearch {
  width: 120px;
  background-color: var(--site-search);
  color: white;
  border: none;
  outline: none;
}

.resetFilter {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.purpleSpinner {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3; /* Light grey background */
  border-top: 5px solid var(--site-purple);
  border-radius: 50%;
  animation: spinner 0.2s linear infinite;
  margin-left: auto;
  margin-right: auto;
}

.sortContainer {
  margin-left: -20px;

}

.categoryIcon {
  width: 50px;
  height: 50px;
  filter: invert(1);
  position: absolute;
  right: calc((50vw - 400px) + 30px);
  top: 15px;
}

.tabs {
  display: flex;
  justify-content: space-evenly; /* Distribute tabs evenly across the available space */
  width: 700px; /* Adjust based on container requirements */
  margin: 10px 0; /* Optional margin adjustment */
  margin-top: -50px;
  margin-left: auto;
  margin-right: auto;
}

.tabButton {
  background: none; /* No button background */
  border: none; /* No button border */
  color: #444; /* Default text color */
  padding: 10px; /* Adjust padding as needed */
  cursor: pointer; /* Pointer cursor for buttons */
  outline: none; /* Remove default focus border */
  font-size: 16px; /* Adjust font size */
  position: relative;
  width: 50%;
}

.active {
  color: white;
}

.active::after {
  content: ""; /* Pseudo-element for the underline */
  position: absolute;
  left: 0;
  bottom: -5px; /* Adjust position if needed */
  width: 100%;
  height: 2px; /* Thickness of the underline */
  background-color: var(--site-purple);
}

.content {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  padding: 0 10px 20px 10px;
  position: relative;
}

.productScroll {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */

  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* For non-Webkit browsers: Additional wrapper might be needed for margin trick */
  margin-bottom: -16px; /* Adjust this value based on your scrollbar height */
}

.contentHeader {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 20px;
  overflow-y: hidden;
  margin-top: 30px;
}

.box, .allBox {
  width: 140px;
  min-width: 140px; /* Prevents the box from getting smaller than 140px */
  height: 240px;
  margin: 0 8px;
  background-color: #222; /* Dark grey */
  border-radius: 10px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: block; 
}




.boxTitle {
  color: #FFFFFF; /* White */
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  height: 30px;
  text-align: center;
  padding-top: 5px;
}

.boxTitle {
  white-space: normal; /* Ensures text wraps */
  overflow: hidden; /* Hides text that overflows */
  text-overflow: ellipsis; /* Adds ellipsis if text overflows */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits text to two lines */
  -webkit-box-orient: vertical;
}

.userTitle {
  color: #FFFFFF; /* White */
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  height: 20px;
  text-align: center;
  padding-top: 5px;
}

.picksText {
  color: #CCCCCC; /* Light grey */
  font-size: 12px;
  margin: 0 10px;
  text-align: center;
}

.image {
  width: 100%;
  height: 200px;
  margin-top: 5px;
  border-radius: 5px;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
}

.userImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 10px auto;
  object-fit: cover;
  cursor: pointer;
}

.bioText {
  color: #CCCCCC; /* Light grey */
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  width: 90%;
  margin: 5px auto;
  height: 30px;
  overflow: hidden;
}

.similarityText {
  color: #CCCCCC; /* Light grey */
  font-size: 12px;
  text-align: center;
  width: 80%;
}

.seeAllText {
  color: white;
  font-size: 18px;
  text-align: center;
  margin-top: 80px;
}

.seeAllBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 140px;
  height: 240px;
  margin: 0 8px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  padding-bottom: 50px;
}

.seeArrow {
  color: white;
  font-size: 24px;
  margin-top: 10px;
}

.scrollLeft {
  position: absolute;
  top: 60%;
  left: 0px;
  transform: translateY(-50%);
  color: rgb(212, 204, 204);
  font-size: 12px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.scrollRight {
  position: absolute;
  top: 60%;
  right: 0px;
  transform: translateY(-50%);
  color: rgb(212, 204, 204);
  font-size: 12px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.infContainer {
  width: 1000px;
  overflow: hidden;
  margin-left: 70px;
}