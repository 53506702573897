/* ReportModal.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;
    color: white;
  }
  
  .modalContainer {
    background-color: rgb(24, 23, 23);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
  }
  
  .reportInput {
    width: 95%;
    margin: 10px 0;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    font-size: 12pt;

  }
  
  .modalButtons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .submit,
  .cancel {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit {
    background-color: var(--site-purple);
    color: white;
  }
  
  .cancel {
    background-color: rgb(30,30,30);
    color: white;
  }