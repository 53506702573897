.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  background-color: var(--site-background);
  padding-top: 100px;
  min-height: 100vh;
}

.search_container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 43px;
  margin-top: -10px;
}

.category_search {
  background-color: rgb(40, 40, 40);
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  outline: none;
  width: 200px;
  font-size: 18px;
}

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 40px;
}

.card {
  background-color: rgb(14, 14, 17);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 150px;
  height: 250px;
  align-items: flex-start;
  justify-content: center;
}

.cardTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 30px;
  width: 95%;
  padding-left: 5px;
}

.card_image {
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 220px; /* you can adjust the height based on what you want */
  object-fit: cover;
}

.card_middle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding-left: 10px;
}

.categoryName {
  font-size: 14px;
  font-weight: bold;
  color: white;
  height: 30px;
  padding-top: 5px;
}

.categoryStat {
  font-size: 16px;
  color: white;
  text-align: left;
  margin: 0;
}

.categoryIcon {
  width: 23px;
  height: 23px;
  object-fit: scale-down;
  filter: invert(1);
  position: static;
}

.card:hover {
  transform: scale(1.05); /* Small zoom effect on hover */
}

.card:hover {
  transform: scale(1.05); /* Small zoom effect on hover */
}

.category_img_container {
  position: absolute;
  top: 50%;
  left: 16px; /* Some padding from the left */
  transform: translateY(-50%); /* Center it vertically */
  width: 30px; /* Adjust width if needed */
  height: 30px; /* Adjust height if needed */
}

.cardLink {
  text-decoration: none;
  color: inherit; /* so that text inside the link retains its styles */
  display: block; /* to make the link block-level */
}

.new_product_button {
  display: flex;
  color: white;
  background-color: rgb(51, 0, 77);
  height: 30px;
  width: 120px;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.new_product_button:hover {
  background-color: rgb(62, 0, 93);
}
