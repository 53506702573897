.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  background-color: var(
    --site-background
  );
  color: #ffffff;
  width: calc(50vw - 400px); /* Corrected calculation */
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  border-left-width: 0.1px;
  border-left-style: solid;
  border-left-color: #484646;
}

.searchBar {
  z-index: 2000;
}

.logoImage {
  width: 300px;
  height: 80px;
  object-fit: contain;
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 250px;
  padding: 10px;
}

.navItem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.navItem:hover {
  box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2); /* White glow */
}

.navIcon {
  width: 40px;
  height: 40px;
  color: #eae3e3;
}

.navText {
  font-size: 20px;
  color: #eae3e3;
  padding-left: 20px;
}

.raters{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;

}

.rater {
  width: 400px;
  background-color: rgb(30,30,30);
  display: flex;
  flex-direction: row;
  border-radius: 25px;
}

.raterImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: black;
  object-fit: cover;
  cursor: pointer;
}

.featuredRaters h2 {
  font-size: 20px;
  margin-left: 120px;
  margin-top: 80px;
  color: rgb(220,220,220);
}

.raterInfo {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-left: 5px;
}

.raterName {
  color: white;
  font-size: 16px;
}

.raterRating {
  color: #CCCCCC;
  font-size: 14px;
}

.followContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--site-purple);
  width: 120px;
  justify-content: center;
  height: 40px;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}

.addText {
  margin-top: 50px;
  color: #CCCCCC;
  font-size: 16px;
  text-align: center;
  margin-left: 80px;
  cursor: pointer;
}

.newProduct {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 20px;
  background-color: var(--site-purple);
  width: 200px;
  justify-content: center;
  height: 40px;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 40px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: absolute;
  bottom: 10px;
  color: white;
  font-size: 12px;
  padding-left: 50px;
}

.privacy {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.contact {
  color: white;
  text-decoration: none;
}


.footer a:hover {
  text-decoration: underline;
  cursor: pointer;
}


