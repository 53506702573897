.product-page{
    width: 80%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
}

.product-title-span {
    display: flex;
    align-items: center; /* Vertically centers the text with the image */
    gap: 10px; /* Spacing between the image and the text */
}

.product-title-span{
    font-size: 24pt;
    font-weight: bold;
}



.product-page-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-page-left-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    gap: 0px;
}

.product-page-left-header-icon{
    width: 10%;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: right;
}

.product-page-left-header-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

.product-page-left-header-titles h1 {
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.product-page-left-header-titles h2 {
    padding-top: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 16pt;
}

.product-page-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start; 
}

.product-page-photo {
    max-width: 100%;
    max-height: 400px;
    object-fit: scale-down;
    border-radius: 10px;
}

.product-page-right-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;  
}

.product-page-right-icons{
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* To align items to the right */
    gap: 0px; /* Spacing between the icons */
    width: 80px;
}

.product-page-icon {
    width: 70px;
    filter: invert();
}

.product-page-right-external {
    padding: 10px;
    width: 100%;
}

.product-page-right-header {
    display: flex; /* Making it a flex container */
    align-items: center; /* Vertically center aligned */
    justify-content: space-between; /* This will push the title to the left and the icons to the right */
    width: 100%;
    height: 180px;
}

.product-page-right-info{
    width: 100%;
}


.product-page-right-description {
    width: 330px;
    text-align: center;
    font-style: italic;
    overflow-y: auto;
    max-height: 150px;
    margin-top: 0px;
    padding-left: 5px;
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: rgb(30, 29, 29);
    border-radius: 10px;
}

.product-page-right-keywords {
    text-align: left;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
    font-weight: bold;
    font-size: 1.4em;
    display: flex;
    flex-wrap: wrap; /* Allows children to wrap onto the next line */
    align-items: center;
    justify-content: center;
    min-height: 0px;
    max-height:5000px;
    overflow-y: auto;
    color: white;
    margin-top: -20px;
}
.product-page-keyword {
    padding: 15px;
    color: white;
    font-style: normal;
    
}

.product-page-keyword:hover {
    text-decoration: underline;
}

/* Last keyword should not have right margin */
.product-page-keyword:last-child {
    margin-right: 0;
}

.product-page-keyword {
    display: flex;
    align-items: flex-start; /* align at the top */
    gap: 3px;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
}

.product-page-keyword > div { /* This targets the direct div child of .product-page-keyword */
    display: flex;
    flex-direction: column;
    align-items: center; /* centers the arrows */
    margin-top: -5px;
    
}

.upvote-arrow, .downvote-arrow {
    cursor: pointer;
    background: none; /* Remove background */
    border: none;     /* Remove border if any */
    font-size: 15px;  /* Adjust size if needed */
    color:rgb(74, 74, 74)
}

.selected {
    color: rgb(157, 0, 255);
}

.product-page-average-rating, .product-page-your-rating, .product-page-predicted-rating{
    font-size: 48pt;
    font-weight: bold;
    margin-bottom: 0px;
    padding-top: 0px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.product-page-average-rating-subtitle,
.product-page-predicted-rating-subtitle,
.product-page-number-ratings,
.product-page-relationship-label,
.product-page-your-rating-subtitle,
.product-page-your-rating-date {
    margin: 1px; /* This sets the top, right, bottom, and left margins to 0 */
    text-align: center;
    padding-top: 0px;
}



.product-page-right-ratings{
    display: flex;
    flex-direction: row;
    align-items: top;
    gap: 20px;
    padding-top: 20px;
    padding-left: 10px;
}


.product-rater-gallery-title{
    font-size: 32px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px;
}

.product-rater-gallery{
    width: 800px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.product-rater-card, .product-rater-card-no-review, .product-rater-card-with-review {
    border: none;
    background-color: rgb(24, 23, 23);
    border-radius: 10px;
    width: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: top;
    height: 260px;
    gap: 10px;
}

.product-rater-card:hover{
    scale: 1.1;
}

.product-rater-card img  {
    margin: 0;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid black;
    background-color: black;
}

.product-rater-card h4{
    padding-bottom: 0px;
    margin: 0;
    padding-top: 5px;
}

.product-rater-card h6{
    padding-bottom: 0px;
    margin: 0;
    font-size: 12px;
    margin-top: -15px;

}

.product-rater-review-link{
    margin-top: -10px;
    text-decoration:underline;
    cursor: pointer;
}



.product-rater-gallery-sort-options{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}


  .product-page-add-keywords {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 10px;

  }

  .product-page-your-review-link{
    margin-left: auto;
    margin-right: auto;
    text-decoration: underline;
    cursor: pointer;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-top: -18px;
  }

  .product-rating-more-button{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
  }

  .product-rating-more-button button {
    background-color: rgb(51, 0, 77);
    margin-bottom: 30px;
    color: white;
    height: 30px;
  }

  .active-tabs {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.active-tabs button {
    background-color: rgb(51, 0, 77);
    color: rgb(255, 255, 255);
    border-radius: 5px;
}

.product-delete{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.product-delete button{
    color: white;
    background-color: rgb(51, 0, 77);
    cursor: pointer;
}