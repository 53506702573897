/* styles.module.css */
.sitePurple {
  color: rgb(86, 0, 107);
}

.siteBackground {
  background-color: var(--site-background);
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  gap: 5px;
  width: 970px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--site-background);
  padding-top: 0px;
}

.title {
  text-align: center;
  font-size: 32px;
  color: white;
}

.gallery {
  padding: 10px;
  padding-bottom: 250px;
  overflow-y: scroll;
  width: 970px;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.ratingCard {
  background-color: var(--site-background);
  margin-bottom: 5px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ratingCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 3.5%;
  width: 93%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.cardMain {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-left: 5px;
  height: 85px;
  width: 100%;
}

.cardMiddle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 10px;
  width: 550px;
}

.cardRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 0px;
  padding-left: 10px;
  width: 150px;
  height: 240px;
  position: relative;
}

.cardHeaderLeft,
.cardFooterLeft,
.commentSectionLeft,
.commentSectionLeftChild {
  display: flex;
}

.cardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.cardFooterLeft {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  align-self: flex-end;
  padding-bottom: 10px;
  gap: 50px;
  width: 500px;
}

.cardFooterRight {
  display: flex;
  justify-content: flex-end;
  padding-top: 45px;
  padding-right: 20px;
}

.ratingStats {
  padding-top: 50px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: flex-end;
  width: 40px;
}

.commentButton {
  background-color: transparent;
  border: none;
}

.commentDetails {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.userPhoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-color: black; /* Ensure no unwanted background color */
  border: none; /* Remove if not needed */
  cursor: pointer;
}

.userPhotoModal {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: contain;
  background-color: black; /* Ensure no unwanted background color */
  border: none; /* Remove if not needed */
  margin-top: 20px;
}

.userName {
  font-size: 20px;
  color: white;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.userName,
.commentName,
.ratingDate,
.commentDate,
.commentMainText {
  color: white;
  font-weight: bold;
}

.ratingReview {
  font-size: 12pt;
  color: white;
  background-color: transparent;
  padding-top: 10px;
  max-height: 65px;
  overflow-y: hidden;
  width: 800px;
  cursor: pointer;
  margin-left: -100px;
}

.textSmall {
  font-size: 14px;
  color: #cccccc;
}

.ratingDate {
  font-size: 16px;
  margin-left: 0px;
  color: #cccccc;
}

.productParentText,
.ratingStatText,
.ratingDisplayLabel,
.ratingDisplayNumber,
.caughtUpText,
.text,
.shareText {
  color: white;
}


.cardHeaderRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.ratingStar {
  color: rgb(86, 0, 107);
  font-size: 32px;
  padding-right: 5px;
  padding-left: 5px;
}

.ratingScore {
  color: white;
  font-size: 32px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.ratingDescription {
  color: #CCCCCC;
  font-size: 16px;
  padding-left: 15px;
}

.ratingIcon,
.modalOverlay,
.ratingCardReview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardFooterMiddle {
  display: flex;
  position: relative;
}

a {
  all: unset;
}

.shareIcon {
  color: grey;
  font-size: 20px;
  cursor: pointer;
  width: 50px;
  padding-top: 10px;
  padding-right: 5px;
}

.ratingIcon {
  color: rgb(86, 0, 107);
  font-size: 20px;
}

.ratingIcon3 {
  font-size: 20px;
  cursor: pointer;
}

.ratingIcon2 {
  font-size: 20px;
  cursor: pointer;
}


.photoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0px solid white;
}

.cardLeft{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 160px;
  height: 240px;
  padding-left: 20px;
  padding-top: 10px;
  cursor: pointer;
}

.productPhoto {
  width: 160px;
  height: 240px;
  object-fit: cover;
  cursor: pointer;
}

.categoryInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 8px;
  gap: 10px;
  font-size: 16px;
  color: #CCCCCC;
}

.categoryPhoto {
  width: 25px;
  height: 25px;
  filter: invert(1);
}

.productContainer {
  padding-top: 40px;
  padding-left: 10px;
} 

.productTitle {
  font-size: 20px;
  color: white;
  text-align: center;

}

.submitButton,
.cancelButton,
.filterButton,
.refreshButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(86, 0, 107);
  color: white;
  border: 0.2px solid white;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2000;
}


.modalView {
  background-color: rgb(20, 20, 20);
  border-radius: 20px;
  padding: 35px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}



.submitIcon,
.submitIconChild,
.replyIcon {
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: rgb(86, 0, 107);
}

.sendIcon {
  display: flex;
  border: 0;
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 40px;
  height: 40px;
  background-color: transparent;
}

.submitIcon,
.submitIconChild,
.replyIcon {
  border: 0;
  color: purple;
  height: 20px;
  width: 20px;
  cursor: pointer;
}



.dot,
.dotActive {
  color: grey;
  margin: 3px;
  font-size: 24px;
}

.dotActive {
  color: white;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
}


.centeredView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3); /* Dimmed background */
  z-index: 1000; /* Ensure it is above other content */
  color: transparent;
}

/* Modal overlay to contain the modal content */
.modalOverlay {
  background-color: #242222; /* Dark grey background */
  color: white; /* White text */
  width: 50%; /* Medium size relative to screen width */
  max-width: 600px; /* Maximum width */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  position: relative; /* For positioning internal elements like the close button */
  display: flex;
  flex-direction: column;
}

/* Close button in the top-right corner */
.cancelButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white; /* White text */
  font-size: 24px;
  cursor: pointer;
}

/* User photo and details header */
.commentHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.userDetails {
  display: flex;
  flex-direction: row;
}

/* Styling for text input area */
.commentInput {
  margin-top: 80px;
  width: 500px; /* Take full width of the modal */
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc; /* Subtle border */
  background: #222; /* Slightly darker than modal for contrast */
  color: white; /* White text */
  margin-bottom: 20px; /* Space before the submit button */
  font-size: 12pt; /* Larger text for readability */
}

/* Submit button styling */
.submitButton {
  background-color: rgb(86, 0, 107);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.commentsContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.commentPhoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  background-color: black; /* Ensure no unwanted background color */
  border: none; /* Remove if not needed */
}

.commentCard {
  border-top: 0.2px solid grey;
  display: flex;
  justify-content: left;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

.commentSectionLeft,
.commentSectionLeftChild {
  display: flex;
  justify-content: left;
  width: 50px;
  padding-right: 10px;
}

.commentSectionLeftChild {
  margin-left: -50px;
}

.commentSectionRight,
.commentSectionRightChild {
  background-color: rgb(41, 38, 38);
  width: 80%;
  border-radius: 10px;
  flex-grow: 1;
  gap: 0px;
}

.commentSectionRightChild {
  margin-top: -10px;
}

.commentHeader {
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
  align-items: center;
  width: 100%;
  height: 50px;
}

.commentName,
.commentMainText {
  color: white;
  font-size: 12px;
  padding: 5px;
}

.commentMainText {
  padding-top: 0;
  max-width: 90%;
}

.commentDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.commentDate {
  color: grey;
  font-size: 10px;
  padding: 5px;
}

.newCommentInput {
  all: unset;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  width: 600px;
  min-height: 40px; /* Initial minimum height */
  max-height: 200px; /* Maximum height when expanded */
  margin: 0;
  box-sizing: border-box;
  overflow: auto; /* Hide vertical and horizontal scrolls */
  padding-left: 10px;
}


.replyCountText {
  color: grey;
  font-size: 12px;
  position: absolute;
  right: 35px;
  bottom: 5px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.purpleSpinner {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3; /* Light grey background */
  border-top: 5px solid var(--site-purple);
  border-radius: 50%;
  animation: spinner 0.2s linear infinite;
}

.carouselContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%; /* Ensure the container takes full width */
  margin-top: 20px;
}

.gallery2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%; /* Ensure the gallery takes full width */
}

.arrow {
  border: none;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0,0,0,0.5);
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.arrow:hover {
  background-color: rgba(0,0,0,0.3);
}

/* Position the arrows at the outer edges of the container */
.arrow:first-of-type {
  left: 0; /* Align the left arrow to the very left edge of the container */
}

.arrow:last-of-type {
  right: 0; /* Align the right arrow to the very right edge of the container */
}

.pagination {
  margin-top: 10px;
}

.dot {
  margin: 0 5px;
  cursor: pointer;
}

.dotActive {
  color: #7204a1;
}

.optionText {
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  align-self: center;
  align-content: center;
  width: 100%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent background */
  z-index: 1000; /* High z-index to keep it on top */
  display: flex; /* Flexbox to position the child elements */
  align-items: start; /* Aligns content to the top */
  justify-content: flex-end; /* Aligns content to the right */
}



.modalBody {
  display: flex;
  flex-direction: column;
  gap: 0px;
  position: absolute;
  right: 50px;
  top: 40px;
  background-color: rgb(24, 23, 23);
  border-radius: 10px;
  z-index: 4000;
  width: 180px;
}

.modalBody h3 {
  cursor: pointer; /* Cursor to indicate clickable */
  transition: background-color 0.3s; /* Transition for hover effects */
}

.modalBody h3:hover {
  background-color: #555; /* Darker background on hover */
}

.copyMessage {
  color: white;
  position: absolute;
  right: 90px;
  top: -30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}


.linkReset {
  text-decoration: none;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  display: block; /* Ensure it behaves like a block element */
  width: 100%; /* Ensure it takes full width available */
}