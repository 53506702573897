.header-search {
  width: 300px;
  height: 46px;
  box-sizing: border-box; /* To make sure padding and border are included in width */
  background-color: rgb(45, 45, 45);
  color: rgb(255, 255, 255);
  border-radius: 50px;
  border: 1px solid rgb(45, 45, 45);
}

.header-search::placeholder {
  color: rgb(210, 210, 210); /* Change to your desired placeholder text color */
  font-size: 15px;
}

.header-search:focus {
  border: 1px solid rgb(45, 45, 45); /* Set to original border color or any other as desired */
  outline: none; /* Optionally remove the default focus outline */
}


.search-dropdown {
  top: 150%; /* It will automatically position itself directly below the search input */
  left: 0; /* Align the dropdown to the left of the search */
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #cccccc;
  border-radius: 4px;
  position: absolute; /* This makes it overlay on top */
  width: 95%;
  background-color: rgb(17, 17, 17);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  z-index: 20;
}

.search-dropdown li {
  padding: 0px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  min-height: 60px;
}

.search-dropdown li:hover {
  background-color: rgb(37, 34, 34);
}

.search-details {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  padding: 5px;
  height: 60px;
  width: 100%;
  gap: 10px;
}

.search-details-left {
  height: 100%;
  width: 60px;
  padding: 0px;
  border-right: 1px solid #beb3b3;
}

.search-details-left img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  border-radius: 4px;
}

.search-details-middle {
  height: 100px;
  width: 75%;
  align-items: left;
  justify-content: left;
  padding: 0px;
  max-height: 100px;
  overflow-y: hidden;
}

.search-product-title {
  display: block; /* Makes it a block-level element */
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds the ellipsis at the end */
  line-height: 1.5em; /* Adjust as needed */
}

.search-product-creator {
  margin-top: -20px;
}

.search-details-right {
  height: 100px;
  width: 50px;
  align-items: right;
  justify-content: right;
  padding: 0px;
  margin-left: auto;
  margin-right: 10px;
}

.search-category-icon {
  margin-right: 50px;
  width: 50px;
  filter: invert();
}

.search-category-image {
  width: 10%;
}

.header-category-dropdown {
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-left: 0.5px solid #ccc;
  border-top: 0.5px solid #ccc;
  border-bottom: 0.5px solid #ccc;
  height: 30px;
  background-color: black;
  margin: 0px;
  color: #ccc;
  width: 40px;
}

.search-add-new-product {
  font-size: 16pt;
  margin-left: 10px;
  margin-top: 10px;
  text-decoration: underline;
}

.search-container {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
}
