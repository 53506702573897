.page_title {
  font-size: 36pt;
  font-weight: bold;
  color: white;
  margin-top: -20px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  background-color: var(--site-background);
}

.headerLink {
  width: 550px;
  margin-right: auto;
  margin-left: 60px;
  height: 80px;
  cursor: pointer;
  margin-top: -20px;
  z-index: 1000;
}

.productName {
  font-size: 20pt;
  font-weight: bold;
  color: white;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  z-index: 10000;
  position: relative;
}

.categoryIcon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  filter: invert(1);
  position: absolute;
  top: 5px;
  right: calc((50vw - 400px) + 25px);
  cursor: pointer;
  background-color: rgba(169, 255, 148, 0.4);
  border-radius: 50%;
  padding: 10px;
}

.categoryDropdown {
  position: relative;
  top: -120px;
  left: 670px;
  z-index: 5000;
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.productMiddle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  color: white;
}

.productLeft {
  flex: 1;
  margin-right: 20px; /* Space between the image and the tabs */
}

.headerTouchable {
  cursor: pointer;
  border: none;
  background: none;
}

.productPhoto {
  position: fixed;
  width: 800px;
  height: 100vh;
  object-fit: cover;
  margin-left: 0px;
  border-radius: 10px;
  margin-top: -20px;
  opacity: 0.08;
  z-index: 0;
}

.imageText {
  text-align: center;
  padding-top: 10px;
  padding-left: 50px;
  color: grey;
}

.productRight {
  flex: 2;
  display: flex;
  flex-direction: column;
  color: white;
  width: 450px;
}

.tabsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 450px;
  margin-top: 10px;
}

.tab {
  flex: 1;
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 10px;
  position: relative; /* For positioning the underline */
  color: #cccccc;
}

.activeTab {
  font-weight: bold;
  color: white;
}

.activeTabUnderline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--site-purple);
}

.activeTabText,
.tabText {
  font-size: 16px;
}

.productRatings {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-height: 330px;
  padding-right: 50px;
}

.predicted {
  text-align: center;
  margin-top: 0px;
}

.predictedRating {
  font-size: 48px;
  font-weight: bold;
}

.predictedText {
  font-size: 14px;
  color: #cccccc;
}

.ratersGallery {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 0px;
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  gap: 10px;
}

.raterInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50px;
  gap: 0px;
}

.ratersSimilarity {
  font-size: 12px;
  color: #cccccc;
  margin: 0;
  padding: 0;
}

.ratersSimilarity2 {
  font-size: 12px;
  color: transparent;
  margin: 0;
  padding: 0;
}

.raterPhoto {
  width: 50px; /* Adjust based on your preference */
  height: 50px; /* Adjust based on your preference */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover;
  background-color: black;
}

.ratersRating {
  margin-top: 5px;
}

.keywordsContainer {
  max-height: 100px; /* Set a maximum height */
  overflow-y: auto; /* Enables vertical scrolling if content overflows */
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.keywordsWrapContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.keywordItem {
  margin: 5px;
  padding: 5px;
  background-color: transparent; /* Light grey background */
  border-radius: 10px; /* Rounded corners */
}

.keywordText {
  font-size: 16px;
}

.productInfoText {
  padding: 10px;
  overflow-y: auto; /* Scroll if content is too long */
  max-height: 100px; /* Maximum height before scrolling */
  width: 700px;
  font-size: 18px;
  text-align: center;
}

/* Modal content styling */
.modalContent {
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 650px;
  height: 500px;
  position: absolute;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  left: calc((50vw - 400px) + 60px);
  bottom: 0px;
}

/* Styles for the stars container */
.starsContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.star {
  cursor: pointer;
  transition: color 0.3s ease;
  width: 55px;
  height: 55px;
}

.highlightedStar {
  color: purple; /* Highlight color */
}

.star:hover {
  color: darkviolet;
}

/* Rating submit button */
.submitButton {
  background-color: var(--site-purple);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: 50px;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  margin-top: -30px;
}

.submitButton:hover {
  background-color: rgba(86, 0, 107, 0.8);
}

/* Title and rating display */
.modalContent h1,
.modalContent p {
  text-align: center;
  margin: 10px 0;
}

.modalContent h1 {
  font-size: 96px;
  height: 120px;
  margin-top: -30px;
}

.ratingOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  align-items: center;
  align-self: center;
  align-content: center;
}

.wishlist,
.skip {
  color: var(--site-purple);
  cursor: pointer;
  font-size: 48px;
}

.wishlist:hover,
.skip:hover {
  color: rgba(86, 0, 107, 0.8);
}

.ratingDescription {
  font-size: 20pt;
  color: white;
  text-align: center;
  font-style: italic;
}

.ratingTitle {
  font-size: 18pt;
  color: white;
  text-align: center;
  font-weight: bold;
  max-height: 35px;
  overflow: hidden;
}

.ratingOptions p {
  margin-top: -2px;
}

.reviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.review {
  width: 80%;
  height: 100px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 15px;
  background-color: transparent;
  color: white;
  font-size: 16pt;
  border: 1px solid rgb(51, 49, 49);
}

.ratingOptions h3 {
  color: white;
  font-size: 14pt;
  margin-top: -5px;
}

.pencil {
  color: rgb(137, 0, 147);
  font-size: 16px;
  cursor: pointer;
}

.pencilContainer {
  position: absolute;
  left: 40px;
  top: 00px;
}

.yourRatingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
}
.ratingText {
  font-size: 28pt;
  color: white;
  cursor: pointer;
  height: 30px;
}

.yourRating {
  font-size: 10pt;
  color: white;
  width: 100%;
  text-align: center;
}

.rateText {
  font-size: 20pt;
  color: white;
  cursor: pointer;
  margin-left: 15px;
}

.selectedRating {
  color: white;
}

.submitText {
  padding-top: 20px;
  font-size: 16pt;
  color: white;
}

.wishlistText {
  color: white;
}

.wishlistContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrowLeft {
  position: absolute;
  top: 100px;
  left: 80px;
  transform: translateY(-50%);
  cursor: pointer;
  color: grey;
  font-size: 48px;
}

.arrowRight {
  position: absolute;
  top: 100px;
  right: 80px;
  transform: translateY(-50%);
  cursor: pointer;
  color: grey;
  font-size: 48px;
}

.flashMessage {
  position: fixed;
  top: 93vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(43, 40, 40, 1);
  color: white;
  padding: 0px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1000;
  width: 710px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.externalIcon {
  width: 15px;
  height: 15px;
  object-fit: contain;
  color: rgb(218, 210, 210);
  cursor: pointer;
  position: absolute;
  left: calc((50vw - 400px) + 30px);
  top: 5px;
}

.emptyText {
  font-size: 12pt;
  color: white;
  text-align: center;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.creatorText,
.distanceText {
  font-size: 16pt;
  color: #cccccc;
  text-align: left;
  width: 600px;
  margin-top: 5px;
}

.playContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  margin-top: 20px;
  z-index: 500;
  cursor: pointer;
  position: relative;
  margin-left: 680px;
}

.playIcon {
  width: 40px;
  height: 40px;
}

.playText {
  font-size: 12pt;
  color: #CCCCCC;
  margin: 0px;
}

.progressBar {
  width: 100%;
  background-color: rgba(200,200,200,0.5);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
}

.progress {
  height: 100%;
  background-color: purple;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.labelContainer {
  position: relative;
  z-index: 1;
}

.label {
  color: white;
  font-size: 14px;
  text-align: center;
  width: 100%;
  font-weight: bold;
}