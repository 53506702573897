.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  background-color: var(
    --site-background
  );
  color: #ffffff;
  width: 220px; /* Corrected calculation */
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  border-right-width: 0px;
  border-right-style: solid;
  border-right-color: #484646;
}


.logoImage:hover {
  filter: brightness(1.5);
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 200px;
  padding: 0px;
  background-color: var(
    --site-background
  );
  z-index: 1000;
  margin-top: 80px;
}

.navItem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  background-color: var(
    --site-background
  );
  z-index: 1000;
  width: 200px;
}

.navItem:hover {
  background-color: rgb(40, 40, 40);
  scale: 1.05;
}

.navIcon {
  width: 30px;
  height: 30px;
  color: #eae3e3;
}

.navPhoto {
  width: 30px;
  height: 30px;
  color: #eae3e3;
  border-radius: 50%;
  object-fit: cover;
}


.navText {
  font-size: 18px;
  color: #eae3e3;
  padding-left: 20px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  width: 200px;
  border-top: 1px solid rgba(255,255,255,0.2); 
  margin-top: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.2); 
  padding-bottom: 20px;
  padding-top: 20px;
}

.socialLinks {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 10px;
}

.socialIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 50%;
  filter: grayscale(90%) brightness(0.7);
  cursor: pointer;
}

.socialIcon:hover, .badgeImage:hover, .badgeImageApple:hover {
  filter: grayscale(0%) brightness(1);
  scale: 1.1;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  bottom: 55px;
  padding-right: 0px;

}

.badgeImage {
  width: 150px;
  height: 80px;
  object-fit: contain;
  filter: grayscale(50%) brightness(0.7);
}


.badgeImageApple {
  width: 135px;
  height: 60px;
  object-fit: contain;
  filter: grayscale(50%) brightness(0.7);
  margin-top: 10px;
}

.privacy {
  color: grey;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.contact {
  color: grey;
  font-size: 14px;
  text-decoration: none;
}


.footer a:hover {
  text-decoration: underline;
  cursor: pointer;
}

