.hidden_input {
    display: none;
  }
  
  .upload_link {
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;

  }

  .image_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }