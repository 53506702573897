body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(
    --site-background
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  padding: 10px;
}

.header a {
  margin-right: 10px;
  color: white;
  text-decoration: none;
}

.header a:hover {
  text-decoration: underline;
}

:root {
  --site-purple: rgb(86, 0, 107);
  --site-background: rgb(20, 20,24);
  --site-shadow: rgb(30, 30, 30);
  --site-search: rgb(45, 45, 45);
}