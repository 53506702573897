.page_title {
    font-size: 36pt;
    font-weight: bold;
    color: white;
    margin-top: -20px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 1000px;
    background-color: var(
      --site-background
    );
  }

  .tabs {
    display: flex;
    justify-content: space-evenly; /* Distribute tabs evenly across the available space */
    width: 950px; /* Adjust based on container requirements */
    margin: 10px 0; /* Optional margin adjustment */
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tabButton {
    background: none; /* No button background */
    border: none; /* No button border */
    color: #444; /* Default text color */
    padding: 10px; /* Adjust padding as needed */
    cursor: pointer; /* Pointer cursor for buttons */
    outline: none; /* Remove default focus border */
    font-size: 16px; /* Adjust font size */
    position: relative;
    width: 33%;
  }
  
  .active {
    color: white;
  }
  
  .active::after {
    content: ""; /* Pseudo-element for the underline */
    position: absolute;
    left: 0;
    bottom: -5px; /* Adjust position if needed */
    width: 100%;
    height: 2px; /* Thickness of the underline */
    background-color: var(--site-purple);
  }

.container p{
    color: white;
    font-size: 18px;
    text-align: left;
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.questionContainer {
    border: 1px solid #4e4848; /* Border color */
    border-radius: 20px; /* Rounded corners */
    padding: 10px; /* Padding inside the container */
    cursor: pointer; /* Change cursor to pointer on hover */
    margin-bottom: 10px; /* Add some space between each FAQ item */
    width: 900px; /* Set the width of the container */
    margin-left: auto; /* Center the container */
    margin-right: auto; /* Center the container */
    margin-bottom: 20px;
  }
  
  .questionText {
    color: #fff; /* White text color */
    margin: 0; /* Remove any default margin */
  }
  
  .answerText {
    color: #000; /* Black text color for the answer */
    padding: 10px; /* Padding for the answer */
  }